import React from 'react';
import {withRouter} from 'react-router-dom';
import GoogleChartsLoader from "./common/utils/google-charts-loader";
import {langDirStyle} from "./common/translation";

const fullViewPaths = [
    '/live',
    '/live/track',
    '/geofences/geometry',
    '/landmarks',
    '/playback'
];

const PageContainer = ({children, location}) => {
    const style = {
        ...langDirStyle()
    };
    if(fullViewPaths.includes(location.pathname))
    {
        style.padding = 'unset';
    }
    return (
        <div className="page-content" style={style}>
            <GoogleChartsLoader loadPackage={['corechart', 'line']}>
                {children}
            </GoogleChartsLoader>
        </div>
    );
};

export default withRouter(PageContainer);