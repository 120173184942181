/**
 * Commands modal
 */

import React, {useState} from 'react';
import {langDirStyle, t} from "../common/translation";
import CommandManagement from "./manage";
import CommandResult from "./results";

const CommandsModal = ({apolloClient, RAlert}) => {
    const [activeTab, setActiveTab] = useState('manage');
    const [footer, setFooter] = useState(null);

    /**
     * Initialize footer
     */
    const initializeFooter = (element) => {
        if(!!element)
        {
            setFooter(element);
        }
    };

    return (
        <div className="card borderless shadow-none" style={{height: '100%', ...langDirStyle()}}>
            <div className="card-header">
                <div className="text-center p-2" style={{fontWeight: 'bolder'}}>
                    {t('Commands')}
                </div>
            </div>
            <div className="card-body">
                <ul className="nav nav-tabs nav-success" role="tablist">
                    {/* manage commands */}
                    <li className="nav-item" role="presentation">
                        <a className={`nav-link${activeTab === 'manage' ? ' active' : ''}`} href="/" role="tab"
                           onClick={(e) => {
                               e.preventDefault();
                               setActiveTab('manage');
                           }
                           }>
                            <div className="d-flex align-items-center">
                                <div className="tab-icon">
                                    <i className={`bx bx-terminal font-18 me-1`}/>
                                </div>
                                <div className="tab-title">{t('Manage commands')}</div>
                            </div>
                        </a>
                    </li>
                    {/* view results */}
                    <li className="nav-item" role="presentation">
                        <a className={`nav-link${activeTab === 'results' ? ' active' : ''}`} href="/" role="tab"
                           onClick={(e) => {
                               e.preventDefault();
                               setActiveTab('results');
                           }
                           }>
                            <div className="d-flex align-items-center">
                                <div className="tab-icon">
                                    <i className={`bx bx-menu font-18 me-1`}/>
                                </div>
                                <div className="tab-title">{t('View results')}</div>
                            </div>
                        </a>
                    </li>
                </ul>
                <div className="tab-content py-3">
                    <div className="tab-pane fade active show" role="tabpanel">
                        {
                            activeTab === 'manage' && footer !== null &&
                            <CommandManagement apolloClient={apolloClient} RAlert={RAlert} footerContainer={footer}/>
                        }
                        {
                            activeTab === 'results' && footer !== null &&
                            <CommandResult apolloClient={apolloClient} RAlert={RAlert} footerContainer={footer}/>
                        }
                    </div>
                </div>
            </div>
            <div ref={initializeFooter} className="card-footer p-3"/>
        </div>
    );
};

export default CommandsModal;