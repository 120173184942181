import React from 'react';
import {useApolloClient, useQuery} from "@apollo/client";
import {NO_CACHE} from "../../common/http/options";
import NOTIFICATION_COUNT_GQL from './count.graphql';
import NOTIFICATION_GQL from './notifications.graphql';
import {fromNow} from "../../common/utils/date";
import launchModal from "../../common/ui/sweet-alert";
import NotificationManager from '../modal';
import {FULLSCREEN_MODAL_OPTIONS} from "../../common/ui/sweet-alert/fullscreen";
import {isRtl, langDirStyle, t} from "../../common/translation";

const NotificationsViewer = () => {
    const apolloClient = useApolloClient();
    const {data: count} = useQuery(NOTIFICATION_COUNT_GQL, NO_CACHE(apolloClient, {}, false, {pollInterval: 60000}));
    const {data: notifications} = useQuery(NOTIFICATION_GQL, NO_CACHE(apolloClient, {limit: 6}, false, {pollInterval: 60000}));

    const viewManageNotifications = (e) => {
        e.preventDefault();
        if(!notifications?.result?.length)
        {
            return;
        }
        launchModal(NotificationManager, {apolloClient, lastID: ''}, FULLSCREEN_MODAL_OPTIONS);
    };

    return (
        <ul className="navbar-nav align-items-center">
            <li className="nav-item dropdown dropdown-large" style={{marginTop: '7px', ...langDirStyle()}}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {
                        count?.result > 0 &&
                        <span className="alert-count" style={{width: '25px', height: '25px', top: '-3px'}}>{count.result > 99? '99+': count.result}</span>
                    }
                    <i className="bx bx-bell"/>
                </a>
                <div className="dropdown-menu dropdown-menu-end" style={{width: '500px', left: '-300px'}}>
                    <a href="/" onClick={viewManageNotifications}>
                        <div className="msg-header">
                            <p className="msg-header-title">{t('Notifications')} ({count?.result ?? 0})</p>
                        </div>
                    </a>
                    <div className="header-notifications-list ps">
                        {
                            !notifications?.result?.length &&
                            <p className="m-3">{t('No notifications!')}</p>
                        }
                        {
                            !!notifications?.result?.length &&
                            notifications.result.map((entry, idx) => {
                                return (
                                    <a className="dropdown-item" href="/" key={`notification-${idx}`} onClick={viewManageNotifications}>
                                        <div className="d-flex align-items-center">
                                            {
                                                entry.isHighPriority &&
                                                <div className={`notify ${entry.isRead? '': 'bg-light-danger text-danger'}`}>
                                                    <i className="bx bx-error"/>
                                                </div>
                                            }
                                            {
                                                !entry.isHighPriority &&
                                                <div className={`notify ${entry.isRead? '': 'bg-light-warning text-warning'}`}>
                                                    <i className="bx bx-info-circle"/>
                                                </div>
                                            }
                                            <div className="flex-grow-1">
                                                <h6 className="msg-name">
                                                    <span className={`msg-time ${isRtl()? 'float-start': 'float-end'}`}>
                                                        {fromNow(entry.notificationTime)}
                                                    </span>
                                                </h6>
                                                <p className="msg-info" style={{color: entry.isRead? 'gray': 'inherit'}}>
                                                    {entry.message.length < 50? entry.message: `${entry.message.substr(0, 40)}..`}
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                )
                            })
                        }
                    </div>
                    {
                        !!notifications?.result?.length &&
                        <a href="/" onClick={viewManageNotifications}>
                            <div className="text-center msg-footer">{t('View & Manage Notifications')}</div>
                        </a>
                    }
                </div>
            </li>
        </ul>
    )
};

export default NotificationsViewer;