/**
 * Utility functions to render complex nested Query (or other) widgets sequentially.
 *
 * For example, instead of:
 *
 * <Query-1>
 *      <Query-2>
 *             <Query-3>
 *
 * You can do:
 *  renderNestedQueries(
 *      ...,
 *      Query-1,
 *      Query-2,
 *      Query-3
 * )
 *
 * which is a lot easier to read & navigate!
 *
 * @param {Object} properties initial params / properties passed from the parent component
 * @param {Object} data data passed between Queries
 * @param next The next in-line renderers
 * @return {*}
 */
export const renderNestedQueries = (properties, data = {}, ...next) => {
    return next[0](properties, data, ...next.slice(1));
};

export const getNextRenderer = (properties, data, ...next) => {
    if(next.length)
    {
        return renderNestedQueries(properties, data, ...next);
    }
    return null;
};