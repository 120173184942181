/**
 * Playback modal
 */

import React, {useCallback, useState} from 'react';
import {setDefaultValues, validityObjContainsFalse} from "../../common/utils/state";
import {isEmptyStr, isValidDateTime, isValidFloat} from "../../common/utils/validation";
import {getNextRenderer, renderNestedQueries} from "../../common/utils/renderer";
import TrackersSelector from "../../common/ui/widgets/trackers-selector";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.min.css';
import '../../common/ui/datepicker/style.scss';
import GeofenceSelector from "../../common/ui/widgets/geofence-selector";
import DriverSelector from "../../common/ui/widgets/driver-selector";
import NumericSpinner from "../../common/ui/widgets/spinner";
import moment from "moment";
import {DB_DATE_TIME_FORMAT, formatLocalDate} from "../../common/utils/date";
import {isRtl, langDirStyle, t, textAlignStyle} from "../../common/translation";

const FIELDS = ['trackerID', 'startTime', 'endTime', 'geofenceID', 'geofenceSide', 'driverID', 'movementLongerThanMins', 'idlingLongerThanMins',
                'stoppingLongerThanMins', 'TripsLongerThanMins'];

const PlaybackModal = ({apolloClient, defaultValues={}, RAlert}) => {
    const overrides = {geofenceSide: 'inside', TripsLongerThanMins: 3, ...defaultValues};
    overrides.startTime = defaultValues.startTime ?? moment(moment().format('YYYY-MM-DD 00:00')).toDate();
    overrides.endTime = defaultValues.endTime ?? new Date();
    const [mainFormValues, setMainFormValues] = useState(setDefaultValues(FIELDS, {}, overrides));
    const [mainFormValidity, setMainFormValidity] = useState(setDefaultValues(FIELDS, {}, {}, true));
    const [spinnerFormValues, setSpinnerFormValues] = useState(setDefaultValues(FIELDS, {}, overrides));

    /**
     * On main form change
     */
    const onMainFormChange = useCallback(({target}) => {
        setMainFormValues({...mainFormValues, [target.id]: target.value});
    }, [mainFormValues]);

    /**
     * On spinner form change
     */
    const onSpinnerFormChange = useCallback(({target}) => {
        setSpinnerFormValues({...spinnerFormValues, [target.id]: target.value});
    }, [spinnerFormValues]);
    /**
     * On validation
     */
    const validateFields = () => {
        const formState = {...mainFormValidity};
        formState.trackerID = !isEmptyStr(mainFormValues.trackerID);
        formState.startTime = !!mainFormValues.startTime && isValidDateTime(formatLocalDate(mainFormValues.startTime, DB_DATE_TIME_FORMAT)) && mainFormValues.endTime > mainFormValues.startTime;
        formState.endTime = !!mainFormValues.endTime && isValidDateTime(formatLocalDate(mainFormValues.endTime, DB_DATE_TIME_FORMAT)) && formState.startTime;
        setMainFormValidity(formState);
        return !validityObjContainsFalse(formState);
    };
    /**
     * On apply click
     */
    const onApplyClick = async () => {
        if(!validateFields())
        {
            return;
        }
        const payload = {
            trackerID: mainFormValues.trackerID,
            startTime: formatLocalDate(mainFormValues.startTime, DB_DATE_TIME_FORMAT),
            endTime: formatLocalDate(mainFormValues.endTime, DB_DATE_TIME_FORMAT),
        };
        if(!isEmptyStr(mainFormValues.geofenceID))
        {
            payload.geofenceID = mainFormValues.geofenceID;
            payload.geofenceSide = mainFormValues.geofenceSide;
        }
        if(!isEmptyStr(mainFormValues.driverID))
        {
            payload.driverID = mainFormValues.driverID;
        }
        if(isValidFloat(spinnerFormValues.TripsLongerThanMins) && parseFloat(spinnerFormValues.TripsLongerThanMins) > 0)
        {
            payload.TripsLongerThanMins = parseFloat(spinnerFormValues.TripsLongerThanMins);
        }
        else {
            payload.TripsLongerThanMins = 3;
        }
        payload.movementLongerThanMins = isValidFloat(spinnerFormValues.movementLongerThanMins) && parseFloat(spinnerFormValues.movementLongerThanMins) > 0? parseFloat(spinnerFormValues.movementLongerThanMins): null;
        payload.idlingLongerThanMins = isValidFloat(spinnerFormValues.idlingLongerThanMins) && parseFloat(spinnerFormValues.idlingLongerThanMins) > 0? parseFloat(spinnerFormValues.idlingLongerThanMins): null;
        payload.stoppingLongerThanMins = isValidFloat(spinnerFormValues.stoppingLongerThanMins) && parseFloat(spinnerFormValues.stoppingLongerThanMins) > 0? parseFloat(spinnerFormValues.stoppingLongerThanMins): null;

        const queryParams = [];
        for(const field of FIELDS)
        {
            queryParams.push(`${field}=${encodeURIComponent(payload[field] ?? '')}`);
        }
        window.location.href = `/playback?${queryParams.join('&')}`;
    };
    /**
     * Renders card
     */
    const renderCard = (args, prevData, ...next) => {
        return (
            <div className="card borderless shadow-none" style={{height: '100%', ...langDirStyle()}}>
                <div className="card-header">
                    <div className="text-center p-2" style={{fontWeight: 'bolder'}}>
                        {t('Playback')}
                    </div>
                </div>
                <div className="card-body" style={{overflowY: 'auto', overflowX: 'hidden'}}>
                    {getNextRenderer(args, prevData, ...next)}
                </div>
                <div className="card-footer p-3">
                    {/** footer */}
                    <div className="row">
                        <div className="input-group justify-content-center">
                            <button type="button" className={`btn btn-outline-secondary ${isRtl()? 'ms-3': ''}`} onClick={() => RAlert.close()}>
                                <i className="bx bx-window-close"/>{t('Close')}
                            </button>
                            <button className={`btn btn-success ${!isRtl()? 'ms-3': ''}`} onClick={onApplyClick}>
                                <i className="bx bx-check-circle"/>
                                {t('Apply')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    /**
     * Renders form
     */
    const renderMain = (args, prevData, ...next) => {
        return (
            <div className="animated fadeIn">
                <div className="form-group p-2">
                    <div className="row">
                        {/* trackerID */}
                        <div className="col-md-4" style={textAlignStyle()}>
                            <label htmlFor="trackerID" className="form-label required">{t('Tracker')}</label>
                            <TrackersSelector domID={'trackerID'} selectedValues={mainFormValues.trackerID} onValueChange={onMainFormChange}
                                              client={apolloClient} isInvalid={!mainFormValidity.trackerID} multiple={false}/>
                        </div>
                        {/* startTime */}
                        <div className="col-md-4" style={textAlignStyle()}>
                            <label htmlFor="startTime" className="form-label required">{t('Start time')}</label>
                            <DatePicker
                                selected={mainFormValues.startTime}
                                onChange={date => onMainFormChange({target: {id: 'startTime', value: date}})}
                                className={'form-control' + (!mainFormValidity.startTime? ' is-invalid': '')}
                                showTimeSelect
                                timeIntervals={15}
                                dateFormat={'yyyy-MM-dd HH:mm'}/>
                        </div>
                        {/* endTime */}
                        <div className="col-md-4" style={textAlignStyle()}>
                            <label htmlFor="endTime" className="form-label required">{t('End time')}</label>
                            <DatePicker
                                selected={mainFormValues.endTime}
                                onChange={date => onMainFormChange({target: {id: 'endTime', value: date}})}
                                className={'form-control' + (!mainFormValidity.endTime? ' is-invalid': '')}
                                showTimeSelect
                                timeIntervals={15}
                                dateFormat={'yyyy-MM-dd HH:mm'}/>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="form-group p-2">
                    <div className="row">
                        {/* driverID */}
                        <div className="col-md-4" style={textAlignStyle()}>
                            <label htmlFor="driverID" className="form-label">{t('Driver')}</label>
                            <DriverSelector domID={'driverID'} selectedValues={mainFormValues.driverID} onValueChange={onMainFormChange}
                                              client={apolloClient} isInvalid={!mainFormValidity.driverID} multiple={false} hasRFID={true}/>
                            <small className="text-muted">{t('Drivers with iButton')}</small>
                        </div>
                        {/* geofenceID */}
                        <div className="col-md-4" style={textAlignStyle()}>
                            <label htmlFor="geofenceID" className="form-label">{t('Geofence')}</label>
                            <GeofenceSelector domID={'geofenceID'} selectedValues={mainFormValues.geofenceID} onValueChange={onMainFormChange}
                                              client={apolloClient} isInvalid={!mainFormValidity.geofenceID} multiple={false}/>
                            <small className="text-muted">{t('Filter history based on geofence')}</small>
                        </div>
                        {/* geofenceSide */}
                        {
                            !!mainFormValues.geofenceID &&
                            <div className="col-md-4" style={textAlignStyle()}>
                                <label htmlFor="geofenceSide" className="form-label">{t('Geofence side')}</label>
                                <select id={'geofenceSide'} className="form-control" value={mainFormValues.geofenceSide} onChange={onMainFormChange}>
                                    <option value={'inside'}>{t('Inside geofence')}</option>
                                    <option value={'outside'}>{t('Outside geofence')}</option>
                                </select>
                                {
                                    mainFormValues.geofenceSide === 'inside' &&
                                    <small className="text-muted">{t('Display history within the geofence')}</small>
                                }
                                {
                                    mainFormValues.geofenceSide === 'outside' &&
                                    <small className="text-muted">{t('Display history outside the geofence')}</small>
                                }
                            </div>
                        }
                    </div>
                </div>
                <hr/>
                <div className="form-group p-2">
                    <div className="row">
                        {/* movementLongerThanMins */}
                        <div className="col-md-4" style={textAlignStyle()}>
                            <label htmlFor="movementLongerThanMins" className="form-label">{t('Display movement longer than')}</label>
                            <NumericSpinner domID={'movementLongerThanMins'} spinnerValue={spinnerFormValues.movementLongerThanMins}
                                            min={0} max={300} step={0.25} onValueChange={onSpinnerFormChange}/>
                            <small className="text-muted">{t('Min movement in minutes')}</small>
                        </div>
                        {/* idlingLongerThanMins */}
                        <div className="col-md-4" style={textAlignStyle()}>
                            <label htmlFor="idlingLongerThanMins" className="form-label">{t('Display idling longer than')}</label>
                            <NumericSpinner domID={'idlingLongerThanMins'} spinnerValue={spinnerFormValues.idlingLongerThanMins}
                                            min={0} max={300} step={0.25} onValueChange={onSpinnerFormChange}/>
                            <small className="text-muted">{t('Min idling in minutes')}</small>
                        </div>
                        {/* stoppingLongerThanMins */}
                        <div className="col-md-4" style={textAlignStyle()}>
                            <label htmlFor="stoppingLongerThanMins" className="form-label">{t('Display parking periods longer than')}</label>
                            <NumericSpinner domID={'stoppingLongerThanMins'} spinnerValue={spinnerFormValues.stoppingLongerThanMins}
                                            min={0} max={300} step={0.25} onValueChange={onSpinnerFormChange}/>
                            <small className="text-muted">{t('Min parking period in minutes')}</small>
                        </div>
                    </div>
                </div>
                <div className="form-group p-2">
                    <div className="row">
                        {/* TripsLongerThanMins */}
                        <div className="col-md-4" style={textAlignStyle()}>
                            <label htmlFor="TripsLongerThanMins" className="form-label">{t('Display trips longer than')}</label>
                            <NumericSpinner domID={'TripsLongerThanMins'} spinnerValue={spinnerFormValues.TripsLongerThanMins}
                                            min={0} max={300} step={0.25} onValueChange={onSpinnerFormChange}/>
                            <small className="text-muted">{t('Min trip period in minutes')}</small>
                        </div>
                    </div>
                </div>
                {getNextRenderer(args, prevData, ...next)}
            </div>
        );
    };
    return (
        renderNestedQueries(
            {},
            {},
            renderCard,
            renderMain
        )
    );
};

export default PlaybackModal;