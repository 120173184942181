import {t} from "../../common/translation";

export const getColumns = () => {
    return [
        {
            headerName: t('Command'),
            field: 'commandContent',
            cellRenderer: 'centerText',
            width: 200,
            resizable: true
        },
        {
            headerName: t('Tracker'),
            field: 'trackerName',
            cellRenderer: 'centerText',
            width: 200,
            resizable: true
        },
        {
            headerName: t('Status'),
            field: '',
            cellRenderer: 'status',
            width: 200,
            resizable: true
        },
        {
            headerName: t('Result'),
            field: 'commandResult',
            cellRenderer: 'commandResult',
            width: 400,
            resizable: true
        },
        {
            headerName: t('Created on'),
            field: 'createdOn',
            cellRenderer: 'userDateTime',
            width: 200,
            resizable: true
        },
        {
            headerName: t('Result received on'),
            field: 'resultReceivedOn',
            cellRenderer: 'userDateTime',
            width: 200,
            resizable: true
        }
    ];
};