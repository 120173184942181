/**
 * Geocoding & zooming to coordinates, used when drawing geofences, landmarks, etc.
 * For geocoding, future version might display multiple results for the user to select from, see this package for an example:
 * http://ericgio.github.io/react-bootstrap-typeahead/
 *
 * For now, the first search result from the backend is used.
 */

import React, {useState} from 'react';
import {mapInternals} from "../map-internals/cache";
import GEOCODE_GQL from './geocode.graphql';
import {useApolloClient, useReactiveVar} from "@apollo/client";
import {submitMutation} from '../../common/http/mutate';
import {langDirStyle, t} from "../../common/translation";

const DEFAULT_LOOKUP_ZOOM = 15;
const COORDINATES_PATTERN = /^\d+\.\d+\s*,\s*\d+\.\d+$/;

const Geocode = () => {
    let lastEnter = null;
    const apolloClient = useApolloClient();
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const mapInternalsState = useReactiveVar(mapInternals);

    if(mapInternalsState === null)
    {
        return null;
    }

    /**
     * When enter is pressed on input
     */
    const onEnter = async (evt) => {
        if (evt.key === 'Enter') {
            evt.preventDefault();
            //zoom to current location
            if(lastEnter && (new Date().getTime() - lastEnter) < 300) {
                if(!navigator.geolocation)
                {
                    return;
                }
                if(!isLoading)
                {
                    setIsLoading(true);
                    navigator.geolocation.getCurrentPosition(function (pos) {
                        setIsLoading(false);
                        if(pos.coords.latitude && pos.coords.longitude)
                        {
                            mapInternalsState.map.setCenter({lat: pos.coords.latitude, lng: pos.coords.longitude});
                            mapInternalsState.map.setZoom(DEFAULT_LOOKUP_ZOOM);
                        }
                    }, function () {
                        setIsLoading(false);
                    });
                }
            }
            else if(COORDINATES_PATTERN.test(search))
            {
                const coords = search.split(',');
                mapInternalsState.map.setCenter({lat: parseFloat(coords[0]), lng: parseFloat(coords[1])});
                mapInternalsState.map.setZoom(DEFAULT_LOOKUP_ZOOM);
            }
            else if(search.trim().length > 0)
            {
                const params = [
                    apolloClient,
                    GEOCODE_GQL,
                    {search},
                    () => {},
                    setIsLoading,
                ];
                const {data} = await submitMutation(...params);
                if(data?.result)
                {
                    mapInternalsState.map.setCenter({lat: data.result.location.lat, lng: data.result.location.lng});
                    mapInternalsState.map.setZoom(DEFAULT_LOOKUP_ZOOM);
                }
            }
            lastEnter = new Date().getTime();
        }
        else if(evt.key === 'Escape')
        {
            if(search === '')
            {
                return;
            }
            setSearch('');
        }
    };

    return (
        <div className="position-relative search-bar-box" style={langDirStyle()}>
            <input type="text" className="form-control search-control" placeholder={t('Address or coordinates ↵')}
                   title={t('Hit enter twice to zoom to your current location')} value={search} onKeyDown={onEnter}
                   onChange={(e) => setSearch(e.target.value)}/>
            <span className="position-absolute top-50 search-show translate-middle-y">
                <i className={`bx ${!isLoading? 'bx-search': 'bx-loader-alt bx-spin'}`}/>
            </span>
            <span className="position-absolute top-50 search-close translate-middle-y">
                <i className="bx bx-x"/>
            </span>
        </div>
    );
};

export default Geocode;