import React, {useState} from "react";

/**
 Displays success alert
*/
const Success = ({children, onHideError=()=>{}, alertClass='alert-success'}) => {
    const [isVisible, setIsVisible] = useState(true);
    /**
     * On close
     */
    const hideError = () => {
        setIsVisible(false);
        onHideError();
    };
    return (
        <>
            {
                isVisible &&
                <div className="row">
                    <div className="col">
                        <div className={`alert ${alertClass} alert-dismissible`} role="alert">
                            {children}
                            <button type="button" className="close" style={{border: 'none'}}
                                    data-dismiss="alert" aria-label="Close" onClick={hideError}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default Success;