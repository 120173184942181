/**
 Numeric spinner
 */

import React from 'react';
import {isValidFloat} from "../../utils/validation";

const NumericSpinner = ({domID, spinnerValue, min, max, step, onValueChange, isDisabled=false, isInvalid=false}) => {
    /**
     * Calculate new value & update parent component
     */
    const onSpinnerChange = (event, step, value, shouldIncrement) => {
        const _value = !isValidFloat(value)? 0: value; //if uninitialized!
        let newValue = shouldIncrement? _value + step: _value - step;
        newValue = newValue > max? max: newValue < min? min: newValue;
        newValue = parseFloat(newValue.toFixed(1));
        onValueChange({target: {id: domID, value: newValue}});
    };
    /**
     * Allows changing value directly, this is handy for spinners with a high max value
     */
    const onTextChange = event => {
        let value = parseFloat(event.target.value);
        if(isNaN(value))
        {
            return;
        }
        onSpinnerChange(event, 0, value, null);
    };

    return (
        <div className="input-group">
            {/* - */}
            <div className="input-group-prepend">
                <button style={{minWidth: '2.5rem', height: '55px'}} onClick={e => onSpinnerChange(e, step, spinnerValue, false)}
                        className="btn btn-decrement btn-outline-secondary" type="button" disabled={isDisabled}>
                    <strong>-</strong>
                </button>
            </div>
            {/* value */}
            <input type="text" id={domID} style={{textAlign: 'center'}} className={'form-control' + (isInvalid? ' is-invalid': '')}
                   value={spinnerValue} min={min} max={max} step={step} onChange={onTextChange}
                   onClick={e => e.stopPropagation()} disabled={isDisabled}/>
            {/* + */}
            <div className="input-group-append">
                <button style={{minWidth: '2.5rem', height: '55px'}} onClick={e => onSpinnerChange(e, step, spinnerValue, true)}
                        className="btn btn-increment btn-outline-secondary" type="button" disabled={isDisabled}>
                    <strong>+</strong>
                </button>
            </div>
        </div>
    );
};

NumericSpinner.defaultProps = {
    step: 1,
    min: 0,
    max: 10
};

export default React.memo(NumericSpinner);