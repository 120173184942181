/**
 Imports landmarks
 */

import React, {useState} from 'react';
import Shimmer from "../../common/ui/shimmer";
import IMPORT_LANDMARKS_GQL from './import.graphql';
import FileUploader from "../../common/ui/widgets/uploader";
import {isRtl, langDirStyle, t, textAlignStyle} from "../../common/translation";

const ImportLandmarksModal = ({apolloClient, RAlert}) => {
    const [isUploadingFile, setIsUploadingFile] = useState(false);
    const [uploadFileError, setUploadFileError] = useState(null);
    const [uploadFileResult, setUploadFileResult] = useState(null);

    return (
        <div className="card" style={{fontSize: '18px', marginBottom: 0}}>
            <div className="card-header">
                <div className="text-center p-2" style={{fontWeight: 'bolder'}}>
                    {t('Import Landmarks')}
                </div>
            </div>
            <div className="card-body" style={{...langDirStyle()}}>
                {
                    isUploadingFile &&
                    <Shimmer/>
                }
                {
                    !isUploadingFile &&
                    <div style={textAlignStyle()}>
                        <ul>
                            <li>
                                {t('Download this')} <a href={'/assets/excel/landmark-upload-template.xlsx'}>{t('template')}</a> {t('to populate & then upload your landmarks.')}
                            </li>
                            <li style={{marginTop: '10px'}}>
                                {t('Use the "Tag" column to create categories/groups of landmarks, tags will be used to refine search results when filtering landmarks.')}
                            </li>
                            <li style={{marginTop: '10px'}}>
                                {t('All columns are optional, with the exception of latitude & longitude. Missing or invalid values get auto generated.')}
                            </li>
                        </ul>
                        <div className={`mt-3 ms-3 ${isRtl()? ' me-3': ''}`}>
                            <FileUploader apolloClient={apolloClient} setUploadFileResult={setUploadFileResult} setUploadFileError={setUploadFileError}
                                          setIsUploadingFile={setIsUploadingFile} isUploadingFile={isUploadingFile} uploadFileError={uploadFileError}
                                          uploadFileResult={uploadFileResult} UPLOAD_FILE_GQL={IMPORT_LANDMARKS_GQL} label={'Import'} displayFileName={false}/>
                        </div>
                        {
                            uploadFileResult !== null &&
                            <div className="mt-2">
                                <hr/>
                                {
                                    uploadFileResult.successCount === 0 && uploadFileResult.failureCount === 0 &&
                                    <div className="ms-3 mt-3">
                                        <i className="bx bx-info-circle me-1"/> {t('No rows were imported!')}
                                    </div>
                                }
                                {
                                    uploadFileResult.successCount > 0 &&
                                    <div className="ms-3 mt-3">
                                        <i className="bx bx-check-circle me-1" style={{color: 'green'}}/>
                                        {t(`Successfully imported or updated {COUNT} row${uploadFileResult.successCount > 1? 's': ''}`).replace('{COUNT}', uploadFileResult.successCount)}
                                    </div>
                                }
                                {
                                    uploadFileResult.failureCount > 0 &&
                                    <div className="ms-3 mt-3">
                                        <i className="bx bx-info-circle me-1" style={{color: 'orange'}}/>
                                        {t(`Failed to import {COUNT} row${uploadFileResult.failureCount > 1? 's': ''}, due to invalid coordinates!`).replace('{COUNT}', uploadFileResult.failureCount)}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
            </div>

            <div className="card-footer p-3">
                <div className="row">
                    <div className="input-group justify-content-center">
                        <button type="button" className="btn btn-outline-secondary" disabled={isUploadingFile} onClick={() => RAlert.close()}>
                            <i className="bx bx-window-close"/>{t('Close')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImportLandmarksModal;