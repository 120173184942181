export const MOVING = 'green';
export const IDLING = 'orange';
export const STOPPED = '#C24641';
export const DISCONNECTED = '#696969';
export const ALL = 'black';

export const COLOR_MAP = {
    m: MOVING,
    i: IDLING,
    s: STOPPED,
    d: DISCONNECTED
};