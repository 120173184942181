import React, {Suspense, lazy} from 'react';
import Menu from './common/ui/navigation';
import Header from './common/ui/topbar/header';
import Footer from './common/ui/footer/footer';
import {isDistributor, isMaster, isUser} from './common/auth/guard/check';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Shimmer from "./common/ui/shimmer";
import './common/ui/tooltips/style.scss';
import PageContainer from "./page-container";
import {FLEET_PROFILE, WAREHOUSE_PROFILE} from "./common/auth/profiles";
import HighPriorityNotifications from "./notifications/high-priority";
import {isRtl} from "./common/translation";

const App = () => {
    let homeComponent;
    if(isUser())
    {
        homeComponent = lazy(() => import('./dashboard/user'));
    }
    else if(isMaster())
    {
        //the entry point for the master account
        homeComponent = lazy(() => import ('./device-family-types'));
    }
    else if(isDistributor(['view-companies']))
    {
        homeComponent = lazy(() => import ('./companies'));
    }
    else if(isDistributor(['view-users']))
    {
        homeComponent = lazy(() => import ('./users'));
    }
    else if(isDistributor(['view-trackers']))
    {
        homeComponent = lazy(() => import ('./trackers'));
    }
    return (
        <div className="wrapper toggled">
            {
                isUser() &&
                <HighPriorityNotifications/>
            }
            <Suspense fallback={<Shimmer/>}>
                <Router>
                    <Menu/>
                    <Header/>
                    <div className={`page-wrapper app-direction-${isRtl()? 'rtl': 'ltr'}`}>
                        <PageContainer>
                            <Switch>
                                {
                                    isMaster() &&
                                    <Route path="/vehicle-types" component={lazy(() => import ('./vehicle-types'))}/>
                                }
                                {
                                    isMaster() &&
                                    <Route path="/reports-setup" component={lazy(() => import ('./reports/master'))}/>
                                }
                                {
                                    isMaster() &&
                                    <Route path="/language-setup" component={lazy(() => import ('./language-setup'))}/>
                                }
                                {
                                    isMaster() &&
                                    <Route path="/stack-stats" component={lazy(() => import ('./stack-stats'))}/>
                                }
                                {
                                    isDistributor(['view-companies']) &&
                                    <Route path="/companies" component={lazy(() => import ('./companies'))}/>
                                }
                                {
                                    (isDistributor(['view-tracker-licenses']) || isUser(['view-tracker-licenses'], FLEET_PROFILE)) &&
                                    <Route path="/company-licenses" component={lazy(() => import ('./licenses'))}/>
                                }
                                {
                                    (isDistributor(['view-users']) || isUser(['view-users'])) &&
                                    <Route path="/user-impersonation-token" component={lazy(() => import ('./users/impersonation-token'))}/>
                                }
                                {
                                    (isDistributor(['view-users']) || isUser(['view-users'])) &&
                                    <Route path="/users" component={lazy(() => import ('./users'))}/>
                                }
                                {
                                    isDistributor(['assign-reports-to-users']) &&
                                    <Route path="/reports-assignment" component={lazy(() => import ('./reports/dist'))}/>
                                }
                                {
                                    isDistributor(['manage-event-registry']) &&
                                    <Route path="/events-default-registry" component={lazy(() => import ('./events/default-registry'))}/>
                                }
                                {
                                    isUser(['view-user-events-registry'], FLEET_PROFILE) &&
                                    <Route path="/events-user-registry" component={lazy(() => import ('./events/user-registry'))}/>
                                }
                                {
                                    isDistributor(['manage-event-registry']) &&
                                    <Route path="/events-io-display" component={lazy(() => import ('./events/io-display'))}/>
                                }
                                {
                                    isDistributor(['manage-tracker-sensors']) &&
                                    <Route path="/trackers/sensors" component={lazy(() => import ('./trackers/sensors'))}/>
                                }
                                {
                                    (isDistributor(['view-trackers']) || isUser(['view-trackers'], FLEET_PROFILE)) &&
                                    <Route path="/trackers" component={lazy(() => import ('./trackers'))}/>
                                }
                                {
                                    isDistributor(['view-rfid-tags']) &&
                                    <Route path="/rfid-tags" component={lazy(() => import ('./rfid-tags'))}/>
                                }
                                {
                                    isDistributor(['view-packets-radar']) &&
                                    <Route path="/packets-radar" component={lazy(() => import ('./packets-radar'))}/>
                                }
                                {
                                    (isDistributor(['view-drivers']) || isUser(['view-drivers'], FLEET_PROFILE)) &&
                                    <Route path="/drivers" component={lazy(() => import ('./drivers'))}/>
                                }
                                {
                                    isUser(['view-warehouses'], WAREHOUSE_PROFILE) &&
                                    <Route path="/warehouses/:id/sensors" component={lazy(() => import ('./warehouses/sensors'))}/>
                                }
                                {
                                    isUser(['view-warehouses'], WAREHOUSE_PROFILE) &&
                                    <Route path="/warehouses/:id/gateways" component={lazy(() => import ('./warehouses/gateways'))}/>
                                }
                                {
                                    isUser(['view-warehouses'], WAREHOUSE_PROFILE) &&
                                    <Route path="/warehouses/:id/groups" component={lazy(() => import ('./warehouses/groups'))}/>
                                }
                                {
                                    (isDistributor(['view-warehouses']) || isUser(['view-warehouses'], WAREHOUSE_PROFILE)) &&
                                    <Route path="/warehouses" component={lazy(() => import ('./warehouses/main'))}/>
                                }
                                {
                                    isUser(['manage-sensor-alerts']) &&
                                    <Route path="/sensor-alerts" component={lazy(() => import ('./sensor-alerts'))}/>
                                }
                                {
                                    isUser(['view-trackers'], FLEET_PROFILE) &&
                                    <Route path="/live/track" component={lazy(() => import ('./live-tracking/track'))}/>
                                }
                                {
                                    isUser(['view-trackers'], FLEET_PROFILE) &&
                                    <Route path="/playback" component={lazy(() => import ('./playback/main'))}/>
                                }
                                {
                                    isUser(['view-trackers'], FLEET_PROFILE) &&
                                    <Route path="/live" component={lazy(() => import ('./live-tracking/cluster'))}/>
                                }
                                {
                                    isUser(['view-geofences'], FLEET_PROFILE) &&
                                    <Route path="/geofences/geometry" component={lazy(() => import ('./geofences/geometry'))}/>
                                }
                                {
                                    isUser(['view-geofences'], FLEET_PROFILE) &&
                                    <Route path="/geofences" component={lazy(() => import ('./geofences/main'))}/>
                                }
                                {
                                    isUser(['view-landmarks'], FLEET_PROFILE) &&
                                    <Route path="/landmarks" component={lazy(() => import ('./landmarks/main'))}/>
                                }
                                {
                                    isUser() &&
                                    <Route path="/dashboard" component={lazy(() => import ('./dashboard/user'))}/>
                                }
                                {
                                    isUser(['generate-user-reports']) &&
                                    <Route path="/reports" component={lazy(() => import ('./reports/user'))}/>
                                }
                                {
                                    isUser(['view-email-notifications']) &&
                                    <Route path="/email-notifications" component={lazy(() => import ('./notifications/emails'))}/>
                                }
                                {
                                    isDistributor(['view-teltonika-inspector']) &&
                                    <Route path="/teltonika-inspector" component={lazy(() => import ('./teltonika-inspector'))}/>
                                }
                                {
                                    !!homeComponent &&
                                    <Route path="/" component={homeComponent}/>
                                }
                            </Switch>
                        </PageContainer>
                    </div>
                    <Footer/>
                </Router>
            </Suspense>
        </div>
    );
};

export default App;