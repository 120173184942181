/**
 * Legacy command results are in ASCII, newer ones are stored in HEX format
 */

import React from 'react';
import trackerCellRenderers from '../../trackers/cell-renderers';
import commonCellRenderers from '../../common/ui/ag-grid/cell-renderers';
import {t} from "../../common/translation";

const isValidHex = (str) => {
    return /^[0-9A-Fa-f]+$/.test(str);
};

const hexToAscii = (hexString) => {
    if (!isValidHex(hexString)) {
        return null; // or throw an error
    }

    let asciiString = [];
    for (let i = 0; i < hexString.length; i += 2) {
        let hexChar = hexString.substring(i, i + 2);
        let asciiChar = String.fromCharCode(parseInt(hexChar, 16));
        asciiString.push(asciiChar);
    }
    return asciiString.join('');
};

const cellRenderers = () => ({
    ...trackerCellRenderers(),
    status: (node) => {
        let label = node.data.isSentToDevice? 'SENT': 'PENDING';
        let color = node.data.isSentToDevice? 'green': 'orange';
        return commonCellRenderers.boldText({value: <span style={{color}}>{t(label)}</span>});
    },
    commandResult: (node) => {
        if(!node.value)
        {
            return '';
        }
        if(!isValidHex(node.value))
        {
            return commonCellRenderers.centerText({value: node.value});
        }
        return commonCellRenderers.centerText({value: hexToAscii(node.value)});
    }
});

export default cellRenderers;