import React from 'react';
import {useApolloClient, useQuery} from '@apollo/client';
import LIVE_STATE_GQL from "../cluster/live-state.graphql";
import {NO_CACHE} from "../../common/http/options";
import Error from "../../common/ui/error";
import Shimmer from "../../common/ui/shimmer";
import {useLocation} from 'react-router-dom';
import {ALL, COLOR_MAP} from "../config/state-color";
import {DB_DATE_TIME_FORMAT, getUserDateTime} from "../../common/utils/date";
import moment from "moment";
import {getQueryValue} from "../../common/utils/routes";
import {Dropdown} from "semantic-ui-react";
import {langDirText, t} from "../../common/translation";

const LiveTrackingSearch = () => {
    const location = useLocation();
    const apolloClient = useApolloClient();
    const {data, error, loading} = useQuery(LIVE_STATE_GQL, NO_CACHE(apolloClient, {filter: {}}));
    const trackerID = getQueryValue(location.search, 'trackerID');
    if(error)
    {
        return <Error>{t('Something went wrong!')}</Error>
    }
    if(loading)
    {
        return <Shimmer/>
    }
    const placeholder = t('Filter trackers');
    const customOptions = data.result.map((entry) => {
        const record = {key: entry.id, value: entry.id, name: entry.name};
        record.text = <span>
            <i className="bx bxs-truck" style={{color: COLOR_MAP[entry.deviceState] ?? ALL}}/>
            <span className="ms-1">{entry.name} ({moment(getUserDateTime(entry.lastSeen, DB_DATE_TIME_FORMAT)).fromNow()})</span>
        </span>;
        return record;
    });
    return (
        <Dropdown
            search={(items, query) => {
                return items
                    .filter(item => `${item.name.toLocaleLowerCase()}`.search((query || '').trim().toLocaleLowerCase()) !== -1);
            }}
            selection
            closeOnChange
            className={`form-control dropdown-container-text-${langDirText()}`}
            onChange={(event, data) => window.location.href = `/live/track?trackerID=${data.value}`}
            options={customOptions}
            value={trackerID}
            placeholder={placeholder}
            noResultsMessage={t('No results found')}
        />
    );
};

export default LiveTrackingSearch;