/**
 Copies selective content to clipboard
 */

import React from 'react';
import './style.scss';

const CopyToClipboard = ({content, title='Copy to clipboard'}) => {
    const onCopy = () => {
        const elem = document.createElement('textarea');
        elem.value = content;
        document.body.appendChild(elem);
        elem.select();
        document.execCommand('copy');
        document.body.removeChild(elem);
    };
    return (
        <span className={`me-1 bx bx-copy copy-to-clipboard`} title={title} onClick={onCopy}/>
    );
};

export default CopyToClipboard;