import PropTypes from "prop-types";
import React from "react";

const getTrackConfig = ({ error, source, target, color, disabled }) => {
    const basicStyle = {
        left: `${source.percent}%`,
        width: `calc(${target.percent - source.percent}% - 1px)`
    };

    if (disabled) return basicStyle;

    const coloredTrackStyle = {
        backgroundColor: `${color}`,
        borderLeft: `1px solid ${color}`,
        borderRight: `1px solid ${color}`,
        opacity: 0.4
    };

    return { ...basicStyle, ...coloredTrackStyle };
};

const Track = ({ error, source, target, color, start, onTrackClick, getTrackProps, disabled }) => (
    <div onClick={() => onTrackClick(start)}
        className={`react_time_range__track${disabled ? "__disabled" : ""}`}
        style={getTrackConfig({ error, source, target, color, disabled })}
        {...getTrackProps()}
    />
);

Track.propTypes = {
    source: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired
    }).isRequired,
    target: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired
    }).isRequired,
    getTrackProps: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

Track.defaultProps = { disabled: false };

export default Track;