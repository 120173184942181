/**
 * Displays a notification
 */

import SAlert from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import {isRtl} from "../../translation";

export const showNotification = async (title, options = {}) => {
    options.icon = options.icon ?? 'success';
    options.position = options.position ?? 'top-end';
    options.timer = options.timer ?? 3000;
    options.backdrop = options.backdrop ?? true;
    SAlert.fire({
        ...options,
        html: `<p style="font-size: 25px; direction: ${isRtl()? 'rtl': 'ltr'};">${title}</p>`,
        showConfirmButton: false
    });
};