/**
 * Displays current location on the slider when history is playing
 */
import React from "react";
import {setPlaybackTimeSliderActiveLocationPercentage} from "../cache";
import {useReactiveVar} from "@apollo/client";

const ProgressBar = () => {
    const playbackPercentageState = useReactiveVar(setPlaybackTimeSliderActiveLocationPercentage);
    return (
        <div
            className={`react_time_range__tick_marker`}
            style={{ left: `${playbackPercentageState}%`, width: '4px', height: '25px', top: '-10px', backgroundColor: 'red' }}
        />
    );
};

export default ProgressBar;