/**
 * Returns query param by name
 */
export const getQueryValue = (search, name) => {
    search = search.replace('?', '');
    for(let result of search.split('&')) {
        let chunk = result.split('=');
        if(chunk[0] === name) {
            return chunk[1];
        }
    }
    return null;
};