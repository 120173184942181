/**
 Export to Excel
 */

import React, {useState} from 'react';
import Shimmer from "../shimmer";
import {submitMutation} from "../../http/mutate";
import Error from "../error";
import {isRtl, langDirStyle, t} from "../../translation";

const ExportToExcelModal = ({apolloClient, GQL_PATH, mutationVariables, RAlert}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [result, setResult] = useState(null);
    /**
     * On export
     */
    const exportToExcel = async () => {
        const params = [
            apolloClient,
            GQL_PATH,
            mutationVariables,
            setErrorMessage,
            setIsLoading
        ];
        const {data} = await submitMutation(...params);
        setResult(data.result);
    };
    return (
        <div className="card" style={{fontSize: '18px', marginBottom: 0, ...langDirStyle()}}>
            <div className="card-header">
                <div className="text-center p-2" style={{fontWeight: 'bolder'}}>
                    {t('Export to Excel')}
                </div>
            </div>
            {
                (errorMessage || isLoading) &&
                <div className="card-body">
                    {
                        errorMessage && <>
                            <Error>{t(errorMessage)}</Error>
                            <hr/>
                        </>
                    }
                    {
                        isLoading &&
                        <Shimmer/>
                    }
                </div>
            }
            <div className="card-footer p-3">
                <div className="row">
                    <div className="input-group justify-content-center">
                        <button type="button" className={`btn btn-outline-secondary ${isRtl()? 'ms-3': ''}`} disabled={isLoading} onClick={() => RAlert.close()}>
                            <i className="bx bx-window-close"/>{t('Close')}
                        </button>
                        {
                            !result &&
                            <button type="button" className={`btn btn-secondary ${!isRtl()? 'ms-3': ''}`} onClick={exportToExcel} disabled={isLoading}>
                                <i className="bx bxs-file-export"/>{t('Export to Excel')}
                            </button>
                        }
                        {
                            result &&
                            <a href={result} target="_blank" className="btn btn-success ms-3" onClick={() => RAlert.close()} rel="noreferrer">
                                <i className="bx bxs-download"/>{t('Download')}
                            </a>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExportToExcelModal;