/**
 Driver selector
*/

import React from 'react';
import {useQuery} from '@apollo/client';
import Loading from "../../loading";
import Error from '../../error';
import DRIVERS_GQL from './drivers.graphql';
import '../../semantic-ui/style.scss';
import SemanticDropdown from "../semantic-dropdown";
import {t} from "../../../translation";

const DriverSelector = ({hasRFID=null, ...props}) => {
    const {data, error, loading} = useQuery(DRIVERS_GQL, {client: props.client, variables: {filter: {hasRFID}, pagination: {page: 0, offset: 0, applyPagination: false}}});
    if(error) return <Error>{t(error.message)}</Error>;
    if(loading) return <Loading/>;
    const placeholder = t(`Select ${props.multiple? 'drivers': 'driver'}`);
    const customOptions = data.result.map((entry) => ({key: entry.id, value: entry.id, text: entry.name}));
    return <SemanticDropdown {...props} customOptions={customOptions} placeholder={placeholder}/>
};

export default React.memo(DriverSelector);