/**
 Command result
*/

import React, {useState} from 'react';
import Error from '../../common/ui/error';
import {getNextRenderer, renderNestedQueries} from "../../common/utils/renderer";
import {useQuery} from "@apollo/client";
import COUNT_GQL from './count.graphql';
import RESULTS_GQL from './results.graphql';
import {NO_CACHE} from "../../common/http/options";
import {PAGINATION_PARAMS} from "../../common/utils/pagination";
import {AgGridReact} from "ag-grid-react";
import {getColumns} from './col-definitions';
import getCellRenderers from './cell-renderers';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import '../../common/ui/ag-grid/style.scss';
import Pagination from "react-js-pagination";
import {isRtl, t} from "../../common/translation";
import Shimmer from "../../common/ui/shimmer";
import ReactDOM from "react-dom";
import {DEFAULT_MAP_SETTINGS} from "../../live-tracking/config/map";

const CommandResult = ({apolloClient, RAlert, footerContainer}) => {
    const [activePage, setActivePage] = useState(1);
    const {data: countData, error: countError, loading: countLoading} = useQuery(COUNT_GQL, NO_CACHE(apolloClient));
    const {data: resultsData, error: resultsError, loading: resultsLoading} = useQuery(RESULTS_GQL, NO_CACHE(apolloClient, {pagination: {...PAGINATION_PARAMS, page: activePage}}, false, {pollInterval: DEFAULT_MAP_SETTINGS.FETCH_INTERVAL_MS}));

    /**
     * Footer
     */
    const Footer = () => {
        return (
            <div className="row">
                <div className="input-group justify-content-center">
                    <button type="button" className={`btn btn-outline-secondary ${isRtl()? 'ms-3': ''}`} onClick={() => RAlert.close()}>
                        <i className="bx bx-window-close"/>{t('Close')}
                    </button>
                </div>
            </div>
        );
    };
    /**
     * Renders card
     */
    const renderCard = (args, prevData, ...next) => {
        return (
            <>
                {getNextRenderer(args, prevData, ...next)}
                {ReactDOM.createPortal(<Footer/>, footerContainer)}
            </>
        );
    };
    /**
     * Grid setup
     */
    const setupGrid = (args, prevData, ...next) => {
        if(countError ?? resultsError)
        {
            return <Error>{t('Something went wrong!')}</Error>
        }
        return (
            <>
                <div className="ag-theme-balham" style={{width: '100%', height: '63vh', padding: '10px'}}>
                    {
                        !!((countLoading || resultsLoading) && !resultsData?.result?.length) &&
                        <Shimmer/>
                    }
                    {
                        !!resultsData?.result?.length &&
                        <AgGridReact
                            columnDefs={getColumns()}
                            frameworkComponents={getCellRenderers(apolloClient)}
                            gridOptions={{enableRtl: isRtl()}}
                            rowData={resultsData?.result ?? []}>
                        </AgGridReact>
                    }
                </div>
                {getNextRenderer(args, prevData, ...next)}
            </>
        );
    };
    /**
     * Pagination
     */
    const setupPagination = () => {
        if(!countData?.result)
        {
            return null;
        }
        return (
            <div className="row justify-content-center">
                <div className="mt-3">
                    <Pagination
                        innerClass={'pagination justify-content-center'}
                        activePage={activePage}
                        itemsCountPerPage={PAGINATION_PARAMS.offset}
                        totalItemsCount={countData.result}
                        pageRangeDisplayed={10}
                        activeClass={'page-item disabled'}
                        linkClass={'page-link'}
                        onChange={(page) => setActivePage(page)}
                    />
                </div>
            </div>
        );
    };
    return (
        renderNestedQueries(
            {},
            {},
            renderCard,
            setupGrid,
            setupPagination
        )
    );
};

export default CommandResult;