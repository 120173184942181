/**
 Controls rendering of components based on account types
*/

import {accountVar} from '../cache';

export const isMaster = () => {
    return accountVar()?.isMaster;
};

export const isDistributor = (permissions=null) => {
    if(accountVar()?.isDistributor && !accountVar()?.isStaff)
    {
        return true;
    }
    if(accountVar()?.isDistributor && accountVar()?.isStaff)
    {
        const staffPermissions = accountVar().permissions;
        if(permissions !== null)
        {
            if(!Array.isArray(permissions))
            {
                throw new Error(`Invalid staff permissions!`);
            }
            for(let entry of permissions)
            {
                if(!staffPermissions.includes(entry))
                {
                    return false;
                }
            }
        }
        return true;
    }
    return false;
};

/**
 *
 * @param {Array<String>} permissions
 * @param {String} profile
 * @returns {boolean}
 */
export const isUser = (permissions=null, profile=null) => {
    if(!accountVar()?.isUser)
    {
        return false;
    }
    if(permissions !== null)
    {
        if(!Array.isArray(permissions))
        {
            throw new Error(`Invalid user permissions!`);
        }
        const userPermissions = accountVar().permissions;
        for(let entry of permissions)
        {
            if(!userPermissions.includes(entry))
            {
                return false;
            }
        }
    }
    if(profile !== null)
    {
        return accountVar().profiles.includes(profile);
    }
    return true;
};