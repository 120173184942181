/**
 Tracker groups selector
*/

import React, {useCallback, useRef, useState} from 'react';
import {useQuery} from "@apollo/client";
import Error from "../../error";
import TRACKER_GROUPS_GQL from './groups.graphql';
import Loading from "../../loading";
import NewTrackerGroup from './new-group';
import SemanticDropdown from "../semantic-dropdown";
import {isDistributor, isUser} from "../../../auth/guard/check";
import {NO_CACHE} from "../../../http/options";
import {isRtl, t} from "../../../translation";

const TrackerGroupsSelector = (props) => {
    const [isCreateGroupVisible, setIsCreateGroupVisible] = useState(false);
    const [refresh, setRefresh] = useState(1);
    const [newSelectionValue, setNewSelectionValue] = useState(null);
    //on rename
    const [recordOnEdit, setRecordOnEdit] = useState(null);
    const userID = props.userid === ''? null: props.userid;
    const skip = isDistributor() && userID === null;
    const {data, error, loading} = useQuery(TRACKER_GROUPS_GQL, NO_CACHE(props.client, {filter: {userID}, refresh}, skip));
    const isShiftKeyPressed = useRef(false);

    /**
     * Enables automatic selection of subgroups for multilevel groups when the shift key is down
     */
    const onValueChange = useCallback(({target}) => {
        const isMultiple = props.multiple === true || typeof props.multiple === 'undefined';
        if(!isMultiple || !isShiftKeyPressed.current || !Array.isArray(props.selectedValues) || !Array.isArray(target.value))
        {
            return props.onValueChange({target: {id: target.id, value: target.value}});
        }
        // difference between current & new selection
        const newValues = [];
        for(const entryID of target.value)
        {
            if(!props.selectedValues.includes(entryID))
            {
                newValues.push(entryID);
            }
        }
        let newSelectionValues = [...target.value];
        // look for parent groups in the new values
        for(const entryID of newValues)
        {
            const subGroupIDs = data?.result?.find(e => e.groupID === entryID)?.subGroupIDs;
            if(!!subGroupIDs?.length)
            {
                newSelectionValues = newSelectionValues.concat(subGroupIDs);
            }
            newSelectionValues.push(entryID);
        }
        props.onValueChange({target: {id: target.id, value: Array.from(new Set(newSelectionValues))}});
    }, [props, data?.result]);

    /**
     * On keydown event
     */
    const onKeyDown = useCallback((e) => {
        isShiftKeyPressed.current = e.shiftKey;
    }, []);

    /**
     * On keyup event
     */
    const onKeyUp = useCallback(() => {
        isShiftKeyPressed.current = false;
    }, []);


    if(error) return <Error>{t(error.message)}</Error>;
    if(loading) return <Loading/>;

    const placeholder = t(`Select tracker ${props.multiple === false? 'group': 'groups'}`);
    const customOptions = skip? []: data.result.map((entry) => ({key: entry.groupID, value: entry.groupID, text: `${entry.groupName} (${entry.trackersCount})`}));
    const _props = {
        ...props,
        newSelectionValue,
        setNewSelectionValue
    };
    delete _props.allowCreateNew;
    delete _props.allowRename;
    //on rename
    const isGroupOwner = skip? null: !!data.result.find(r => (parseInt(r.groupID) === parseInt(props.selectedValues) && r.isGroupOwner));

    return <SemanticDropdown {..._props} onValueChange={onValueChange} onKeyDown={onKeyDown} onKeyUp={onKeyUp} selectedValues={props.selectedValues} customOptions={customOptions} placeholder={placeholder}>
        {
            (!props.isDisabled && (isDistributor() || isUser(['create-tracker-groups'])) && !isCreateGroupVisible && (props.allowCreateNew || props.allowRename)) &&
            <div className="mt-1">
                <div className="input-group">
                    {
                        props.allowCreateNew &&
                        <small className="form-text text-muted">
                            <span style={{float: 'right'}}>
                                <a href="/" onClick={(e) => {
                                    e.preventDefault();
                                    setIsCreateGroupVisible(true);
                                    setRecordOnEdit(null);
                                }}>{t('new group')}
                                </a>
                            </span>
                        </small>
                    }
                    {
                        (props.allowRename && !Array.isArray(props.selectedValues) && !!props.selectedValues && isGroupOwner) &&
                        <small className="form-text text-muted" style={{marginLeft: props.allowCreateNew? '15px': '0', marginRight: isRtl()? '15px': 'inherit'}}>
                            <span style={{float: 'right'}}>
                                <a href="/" onClick={(e) => {
                                    e.preventDefault();
                                    const record = data.result.find(r => (parseInt(r.groupID) === parseInt(props.selectedValues)));
                                    if(!!record)
                                    {
                                        setIsCreateGroupVisible(true);
                                        setRecordOnEdit({groupID: record.groupID, groupName: record.groupName});
                                    }
                                }}>{t('rename group')}
                                </a>
                            </span>
                        </small>
                    }
                </div>
            </div>
        }
        {
            (!isCreateGroupVisible && props.allowRename && !props.selectedValues) &&
            <>
                <hr/>
                <div className="mt-1">
                    <small className="text-muted">{t('Select a group to rename')}</small>
                </div>
            </>
        }
        {
            (!isCreateGroupVisible && props.allowRename && !!props.selectedValues && !isGroupOwner) &&
            <>
                <hr/>
                <div className="mt-1">
                    <small className="text-muted">{t('This group is created by another user and cannot be renamed!')}</small>
                </div>
            </>
        }
        {
            isCreateGroupVisible &&
            <NewTrackerGroup client={props.client} userID={userID} setIsCreateGroupVisible={setIsCreateGroupVisible}
                             setNewSelectionValue={setNewSelectionValue} recordOnEdit={recordOnEdit}
                             refresh={refresh} setRefresh={setRefresh}/>
        }
    </SemanticDropdown>
};

export default TrackerGroupsSelector;