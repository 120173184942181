import React, {useEffect, useState} from 'react';
import TopbarOptionsMenu from "../../live-tracking/topbar/options-menu";
import {geofenceDrawingColor, geofenceDrawingCommand, geofenceDrawingCommandCounter} from './cache';
import {useReactiveVar} from "@apollo/client";
import {ChromePicker} from "react-color";
import {t} from "../../common/translation";

const GeofenceGeometryTopbar = () => {
    const counter = useReactiveVar(geofenceDrawingCommandCounter);
    const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
    const [activeColor, setActiveColor] = useState(null);
    const colorPickerBtnStyle = {};
    if(activeColor !== null)
    {
        colorPickerBtnStyle.backgroundColor = activeColor;
    }
    /**
     * Reset state on exit
     */
    useEffect(() => {
        return () => {
            geofenceDrawingCommand(null);
            geofenceDrawingCommandCounter(0);
            geofenceDrawingColor(null);
        };
    }, []);
    /**
     * Fires off command
     */
    const publishCommand = (command, props={}) => {
        geofenceDrawingCommand({command, ...props});
        geofenceDrawingCommandCounter(counter + 1);
    };

    return (
        <div className="top-menu ms-auto">
            <ul className="navbar-nav align-items-center">
                <TopbarOptionsMenu/>
                <div className="btn-group" role="group">
                    <button type="button" className="btn btn-outline-dark" title={t('Pan the map')} onClick={() => publishCommand('map-pan')}>
                        <i className="bx bx-move"/>
                    </button>
                    <button type="button" className="btn btn-outline-dark" title={t('Draw a polygon')} onClick={() => publishCommand('draw-polygon')}>
                        <i className="bx bx-shape-polygon"/>
                    </button>
                    <button type="button" className="btn btn-outline-dark" title={t('Draw a circle')} onClick={() => publishCommand('draw-circle')}>
                        <i className="bx bx-circle"/>
                    </button>
                    <button type="button" className="btn btn-outline-dark" style={colorPickerBtnStyle} title={t('Set color')} onClick={() => {
                        setIsColorPickerVisible(!isColorPickerVisible);
                    }}>
                        <i className="bx bx-color-fill"/>
                    </button>
                    <button type="button" className="btn btn-outline-dark" title={t('Clear drawing')} onClick={() => publishCommand('clear-overlay')}>
                        <i className="bx bx-eraser"/>
                    </button>
                    <button type="button" className="btn btn-outline-dark" style={{backgroundColor: '#90ee90'}} title={t('Save geofence')}
                            onClick={() => publishCommand('done')}>
                        {t('Done')}
                        <i className="bx bx-check"/>
                    </button>
                </div>
            </ul>
            {
                isColorPickerVisible &&
                <div style={{position: 'absolute', top: '60px', right: '200px'}}>
                    <ChromePicker color={activeColor ?? '#000000'} onChangeComplete={(color) => {
                        setActiveColor(color.hex);
                        publishCommand('set-color');
                        geofenceDrawingColor(color.hex);
                    }
                    }/>
                </div>
            }
        </div>
    );
};

export default GeofenceGeometryTopbar;