/**
 * Exported from https://raw.githubusercontent.com/odynvolk/omit-deep-lodash/master/src/index.js with a few modifications
 */

function omitDeepLodash(input, prop) {
    function omitDeepOnOwnProps(obj) {
        if (typeof input === "undefined") {
            return input;
        }

        if (!Array.isArray(obj) && !isObject(obj)) {
            return obj;
        }

        if (Array.isArray(obj)) {
            return omitDeepLodash(obj, prop);
        }

        const o = {};
        for (const [key, value] of Object.entries(obj)) {
            o[key] = !isNil(value) ? omitDeepLodash(value, prop) : value;
        }
        const _o = {...o};
        delete _o[prop];
        return _o;
    }

    if (arguments.length > 2) {
        prop = Array.prototype.slice.call(arguments).slice(1);
    }

    if (Array.isArray(input)) {
        return input.map(omitDeepOnOwnProps);
    }

    return omitDeepOnOwnProps(input);
}

function isNil(value) {
    return value === null || value === undefined;
}

function isObject(obj) {
    return Object.prototype.toString.call(obj) === "[object Object]";
}

export default omitDeepLodash;