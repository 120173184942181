import {toTitleCase} from "../../common/utils/string";
import {langDirText, t} from "../../common/translation";

const getTrackerColumns = () => {
    return [
        {
            headerName: t('Name'),
            field: 'tracker.name',
            cellRenderer: 'centerText',
            pinned: langDirText(),
            resizable: true
        },
        {
            headerName: t('License plate'),
            field: '',
            cellRenderer: 'licensePlate',
            pinned: langDirText(),
            resizable: true
        },
        {
            headerName: t('Device time'),
            field: 'lastPacket.deviceTime',
            cellRenderer: 'userDbDateTime',
            pinned: langDirText(),
            resizable: true
        },
        {
            headerName: t('State'),
            field: 'lastPacket.deviceState',
            cellRenderer: 'deviceState',
            width: 130,
            pinned:langDirText(),
            resizable: true
        },
    ];
};
const getLocationColumns = () => {
    return [
        {
            headerName: t('Address'),
            field: 'lastPacket.address',
            cellRenderer: 'centerText',
            width: 300,
            resizable: true
        },
        {
            headerName: t('Coordinates'),
            field: 'lastPacket.location',
            cellRenderer: 'coordinates',
            resizable: true
        },
    ];
};
const getAttrsColumns = () => {
    return [
        {
            headerName: t('Speed'),
            field: 'lastPacket.speed',
            cellRenderer: 'centerText',
            width: 100,
            resizable: true
        },
        {
            headerName: t('Altitude'),
            field: 'lastPacket.altitude',
            cellRenderer: 'centerText',
            width: 100,
            resizable: true
        },
        {
            headerName: t('Odometer'),
            field: 'lastPacket.odometerKMs',
            cellRenderer: 'odometerKMs',
            width: 100,
            resizable: true
        },
    ];
};
/**
 * Detects if sensor is available on packets
 */
const detectSensor = (sensorType, data, isArray=false) => {
    for(const entry of data)
    {
        if(entry?.sensors && entry.sensors?.[sensorType] && (!isArray || entry.sensors[sensorType].length))
        {
            return true;
        }
    }
    return false;
};

const getWeightColumns = (data) => {
    let labels = [];
    for(const entry of data)
    {
        if(entry?.sensors?.weight)
        {
            labels = labels.concat(entry.sensors.weight.filter(r => (r.io !== null && r.displayIOValue) || r.io === null).map(r => r.label));
        }
    }
    labels = Array.from(new Set(labels));
    return labels.map(label => ({
        headerName: t(toTitleCase(label)),
        field: label,
        cellRenderer: 'weight',
        width: 150,
        resizable: true
    }));
};

const getTemperatureHumidityColumns = (data) => {
    const columns = [];
    const processedColumns = {};
    for(const entry of data)
    {
        if(entry?.sensors?.temperature)
        {
            for(const sensorEntry of entry.sensors.temperature)
            {
                if(!!processedColumns[sensorEntry.label])
                {
                    continue;
                }
                columns.push({
                    headerName: t(toTitleCase(sensorEntry.label)),
                    field: sensorEntry.label,
                    cellRenderer: 'temperature',
                    width: 300,
                    resizable: true
                });
                processedColumns[sensorEntry.label] = true;
            }
        }
    }
    return columns;
};

export const getColumns = (data) => {
    const columns = [];
    columns.push({
        headerName: t('Tracker'),
        children: getTrackerColumns()
    });
    columns.push({
        headerName: t('Location'),
        children: getLocationColumns()
    });
    columns.push({
        headerName: t('Attributes'),
        children: getAttrsColumns()
    });
    if(detectSensor('iButton', data))
    {
        columns.push({
            headerName: t('Driver'),
            field: 'iButton',
            cellRenderer: 'iButton',
            width: 200,
            resizable: true
        });
    }
    if(detectSensor('weight', data, true))
    {
        columns.push({
            headerName: t('Weight'),
            children: getWeightColumns(data)
        });
    }
    if(detectSensor('temperature', data, true))
    {
        columns.push({
            headerName: t('Temperature & Humidity'),
            children: getTemperatureHumidityColumns(data)
        });
    }
    if(detectSensor('PTO', data))
    {
        columns.push({
            headerName: t('Power Transfer Operator'),
            field: 'PTO',
            cellRenderer: 'booleanSensor',
            width: 200,
            resizable: true
        });
    }
    if(detectSensor('seatBelt', data))
    {
        columns.push({
            headerName: t('Seat Belt'),
            field: 'seatBelt',
            cellRenderer: 'booleanSensor',
            width: 200,
            resizable: true
        });
    }
    if(detectSensor('doorState', data))
    {
        columns.push({
            headerName: t('Door State'),
            field: 'doorState',
            cellRenderer: 'booleanSensor',
            width: 200,
            resizable: true
        });
    }
    if(detectSensor('refrigeratorState', data))
    {
        columns.push({
            headerName: t('Refrigerator State'),
            field: 'refrigeratorState',
            cellRenderer: 'booleanSensor',
            width: 200,
            resizable: true
        });
    }
    if(detectSensor('headlights', data))
    {
        columns.push({
            headerName: t('Headlights'),
            field: 'headlights',
            cellRenderer: 'booleanSensor',
            width: 200,
            resizable: true
        });
    }
    return columns;
};