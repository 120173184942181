import React from 'react';
import trackerCellRenderers from '../../trackers/cell-renderers';
import commonCellRenderers from '../../common/ui/ag-grid/cell-renderers';
import {ALL, COLOR_MAP} from "../config/state-color";
import {t} from "../../common/translation";

const cellRenderers = () => ({
    ...trackerCellRenderers(),
    licensePlate: (node) => {
        return commonCellRenderers.centerText({value: node.data.tracker.englishPlateNumber ?? node.data.tracker.plateNumber});
    },
    deviceState: (node) => {
        let label;
        if(node.value === 'm')
        {
            label = 'MOVING';
        }
        else if(node.value === 'i')
        {
            label = 'IDLING';
        }
        else if(node.value === 's')
        {
            label = 'STOPPED';
        }
        else if(node.value === 'd')
        {
            label = 'DISCONNECTED';
        }
        return commonCellRenderers.boldText({value: <span style={{color: COLOR_MAP[node.value] ?? ALL}}>{t(label)}</span>});
    },
    odometerKMs: (node) => {
        if(!node.value)
        {
            return '';
        }
        return commonCellRenderers.centerText({value: `${node.value} kms`});
    },
    weight: (node) => {
        if(!node.data.lastPacket?.sensors?.weight?.length)
        {
            return '';
        }
        for(const entry of node.data.lastPacket.sensors.weight)
        {
            if(entry.label === node.colDef.field)
            {
                return commonCellRenderers.centerText({value: `${entry.totalKG} ${entry.unitLabel}`});
            }
        }
        return '';
    },
    temperature: (node) => {
        if(!node.data.lastPacket?.sensors?.temperature?.length)
        {
            return '';
        }
        for(const entry of node.data.lastPacket.sensors.temperature)
        {
            if(entry.label === node.colDef.field)
            {
                let text = ['Temperature: '];
                if(!!entry?.temperature?.error)
                {
                    text.push(entry.temperature.error);
                }
                else if(entry?.temperature?.value !== undefined)
                {
                    text.push(`${entry.temperature.value}${entry.temperature.unitLabel}`);
                }
                else {
                    text.push('N/A');
                }
                if(entry?.battery?.value !== undefined)
                {
                    text.push(`, Battery: ${entry.battery.value}${entry.battery.unitLabel}`);
                }
                if(!!entry?.humidity?.error)
                {
                    text.push(`, Humidity: ${entry.humidity.error}`);
                }
                else if(entry?.humidity?.value !== undefined)
                {
                    text.push(`, Humidity: ${entry.humidity.value}${entry.humidity.unitLabel}`);
                }
                return commonCellRenderers.centerText({value: text.join('')});
            }
        }
        return '';
    },
    booleanSensor: (node) => {
        if(!node.data.lastPacket?.sensors?.[node.colDef.field])
        {
            return '';
        }
        return commonCellRenderers.centerText({value: node.data.lastPacket?.sensors?.[node.colDef.field].label});
    },
    iButton: (node) => {
        if(!node.data.lastPacket?.sensors?.iButton)
        {
            return '';
        }
        return commonCellRenderers.centerText({value: node.data.lastPacket.sensors.iButton.driverName});
    }
});

export default cellRenderers;