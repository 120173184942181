/**
 List view
*/

import React, {useState} from 'react';
import Error from '../../common/ui/error';
import {getNextRenderer, renderNestedQueries} from "../../common/utils/renderer";
import {useQuery} from "@apollo/client";
import COUNT_GQL from './count.graphql';
import LIST_VIEW_GQL from './list-view.graphql';
import {NO_CACHE} from "../../common/http/options";
import {PAGINATION_PARAMS} from "../../common/utils/pagination";
import SearchFilter from "../../common/ui/widgets/search";
import DeviceStateSelector from "../../common/ui/widgets/device-state-selector";
import TrackerGroupsSelector from "../../common/ui/widgets/tracker-groups-selector";
import {AgGridReact} from "ag-grid-react";
import {getColumns} from './col-definitions';
import getCellRenderers from './cell-renderers';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import '../../common/ui/ag-grid/style.scss';
import Pagination from "react-js-pagination";
import {DEFAULT_MAP_SETTINGS} from "../config/map";
import {isRtl, langDirStyle, t} from "../../common/translation";
import EXPORT_TO_EXCEL_GQL from './export.graphql';
import Shimmer from "../../common/ui/shimmer";
import {submitMutation} from "../../common/http/mutate";
import Loading from "../../common/ui/loading";

const LiveTrackingListView = ({apolloClient, RAlert}) => {
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState('');
    const [deviceStates, setDeviceStates] = useState([]);
    const [trackerGroupIDs, setTrackerGroupIDs] = useState([]);
    const queryFilter = {
        trackerFilter: {
            trackerGroupIDs: !trackerGroupIDs.length? null: trackerGroupIDs,
            search: !search.trim().length? null: search.trim()
        },
        deviceStates: !deviceStates.length? null: deviceStates
    };
    const {data: countData, error: countError, loading: countLoading} = useQuery(COUNT_GQL, NO_CACHE(apolloClient, queryFilter));
    const {data: trackersData, error: trackersError, loading: trackersLoading} = useQuery(LIST_VIEW_GQL, NO_CACHE(apolloClient, {...queryFilter, pagination: {...PAGINATION_PARAMS, page: activePage}}, false, {pollInterval: DEFAULT_MAP_SETTINGS.FETCH_INTERVAL_MS}));
    const [excelExportResult, setExcelExportResult] = useState(null);
    const [isExportingExcel, setIsExportingExcel] = useState(false);

    /**
     * Export to Excel
     */
    const exportToExcel = async () => {
        setIsExportingExcel(true);
        setExcelExportResult(null);
        const params = [
            apolloClient,
            EXPORT_TO_EXCEL_GQL,
            queryFilter,
            ()=>{},
            setIsExportingExcel
        ];
        const {error, data} = await submitMutation(...params);
        if (!error) {
            setExcelExportResult(data.result);
        } else {
            setExcelExportResult(null);
        }
        setIsExportingExcel(false);
    };
    /**
     * Renders card
     */
    const renderCard = (args, prevData, ...next) => {
        return (
            <div className="card borderless shadow-none" style={{height: '100%', ...langDirStyle()}}>
                <div className="card-header">
                    <div className="text-center p-2" style={{fontWeight: 'bolder'}}>
                        {t('List View')}
                    </div>
                </div>
                <div className="card-body" style={{overflowY: 'auto', overflowX: 'hidden'}}>
                    {getNextRenderer(args, prevData, ...next)}
                </div>
                <div className="card-footer p-3">
                    {/** footer */}
                    <div className="row">
                        <div className="input-group justify-content-center">
                            <button type="button" className="btn btn-outline-secondary" onClick={() => RAlert.close()}>
                                <i className="bx bx-window-close"/>{t('Close')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    /**
     * Grid toolbar
     */
    const setupToolbar = (args, prevData, ...next) => {
        return (
            <>
                <div className="row ml-1 mr-1 justify-content-start">
                    <div className="col-md-10">
                        <div className={`input-group ${isRtl()? 'me-2': ''}`}>
                            <SearchFilter notifyOnChange={(query) => {setActivePage(1); setSearch(query);}} value={search}/>
                            <div className="col-md-3 ms-1">
                                <DeviceStateSelector client={apolloClient} domID={'deviceStates'} selectedValues={deviceStates} displayLabel={false}
                                                     onValueChange={(e) => {setActivePage(1); setDeviceStates(e.target.value)}}/>
                            </div>
                            <div className="col-md-5 ms-1">
                                <TrackerGroupsSelector client={apolloClient} domID={'trackerGroupIDs'} selectedValues={trackerGroupIDs} displayLabel={false}
                                                       onValueChange={(e) => {setActivePage(1); setTrackerGroupIDs(e.target.value)}}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2" style={{textAlign: 'right'}}>
                        <button type="button" className="btn btn-outline-success me-2" disabled={isExportingExcel} onClick={exportToExcel}>
                            {!isExportingExcel && <i className="bx bx-spreadsheet"/>}
                            {isExportingExcel && <Loading/>}
                        </button>
                        {
                            excelExportResult !== null &&
                            <a href={excelExportResult} className="btn btn-outline-secondary me-2" target="_blank" rel="noreferrer noopener">
                                <i className="bx bx-download"/>
                            </a>
                        }
                    </div>
                </div>
                {getNextRenderer(args, prevData, ...next)}
            </>
        );
    };
    /**
     * Grid setup
     */
    const setupGrid = (args, prevData, ...next) => {
        if(countError ?? trackersError)
        {
            return <Error>{t('Something went wrong!')}</Error>
        }
        return (
            <>
                <div className="ag-theme-balham" style={{width: '100%', height: '68vh', padding: '10px'}}>
                    {
                        !!((countLoading || trackersLoading) && !trackersData?.result?.length) &&
                        <Shimmer/>
                    }
                    {
                        !!trackersData?.result?.length &&
                        <AgGridReact
                            columnDefs={getColumns((trackersData?.result ?? []).map(r => r.lastPacket))}
                            frameworkComponents={getCellRenderers(apolloClient)}
                            gridOptions={{enableRtl: isRtl()}}
                            rowData={trackersData?.result ?? []}>
                        </AgGridReact>
                    }
                </div>
                {getNextRenderer(args, prevData, ...next)}
            </>
        );
    };
    /**
     * Pagination
     */
    const setupPagination = () => {
        if(!countData?.result)
        {
            return null;
        }
        return (
            <div className="row justify-content-center">
                <div className="mt-3">
                    <Pagination
                        innerClass={'pagination justify-content-center'}
                        activePage={activePage}
                        itemsCountPerPage={PAGINATION_PARAMS.offset}
                        totalItemsCount={countData.result}
                        pageRangeDisplayed={10}
                        activeClass={'page-item disabled'}
                        linkClass={'page-link'}
                        onChange={(page) => setActivePage(page)}
                    />
                </div>
            </div>
        );
    };
    return (
        renderNestedQueries(
            {},
            {},
            renderCard,
            setupToolbar,
            setupGrid,
            setupPagination
        )
    );
};

export default LiveTrackingListView;