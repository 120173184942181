/**
 Custom-field management modal.
 Shared between trackers & landmarks.
 uploadCustomDataModal is used by landmarks, trackers has an entry in actions-list for that
*/

import React, {useState} from 'react';
import {getNextRenderer, renderNestedQueries} from "../../utils/renderer";
import {isRtl, langDirStyle, t} from "../../translation";
import {useQuery} from "@apollo/client";
import {NO_CACHE} from "../../http/options";
import Shimmer from "../../ui/shimmer";
import Error from "../../ui/error";
import Loading from "../../ui/loading";
import {isEmptyStr} from "../../utils/validation";
import {submitMutation} from "../../http/mutate";
import {showNotification} from "../sweet-alert/notifications";
import launchModal from "../sweet-alert";

const CustomFieldManagementModal = ({apolloClient, customFieldsGql, setCustomFieldsGql, uploadCustomDataModal=null, RAlert}) => {
    const {data, error, loading} = useQuery(customFieldsGql, NO_CACHE(apolloClient, {ownFieldsOnly: true}));
    const [formValues, setFormValues] = useState(null);
    const [formValidity, setFormValidity] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionError, setSubmissionError] = useState(null);

    if(!!data?.result && formValues === null)
    {
        setFormValues(data.result.map(r => ({id: r.id, fieldName: r.fieldName, fieldDescription: r.fieldDescription ?? ''})));
        setFormValidity(data.result.map(_ => ({fieldName: true})));
    }
    if(loading)
    {
        return <Shimmer/>;
    }
    if(error)
    {
        return <Error>{t('Something went wrong!')}</Error>;
    }
    if(formValues === null)
    {
        return;
    }

    /**
     * on field change
     */
    const onFieldChange = (idx, fieldID, fieldValue) => {
        const values = [...formValues];
        values[idx] = {...values[idx], [fieldID]: fieldValue}
        setFormValues(values);
    };

    /**
     * Validates fields
     */
    const validateFields = () => {
        const validity = [];
        let isAllValid = true;
        for(const entry of formValues)
        {
            let isEntryValid = true;
            if(entry.fieldName.trim() === '' || formValues.filter(r => r.fieldName === entry.fieldName.trim()).length > 1)
            {
                isAllValid = false;
                isEntryValid = false
            }
            validity.push({fieldName: isEntryValid});
        }
        setFormValidity(validity);
        return isAllValid;
    };

    /**
     * On apply click
     */
    const onApplyClick = async (launchUploadModal) => {
        if(!validateFields())
        {
            return;
        }
        const payload = [];
        for(const entry of formValues)
        {
            payload.push({
                id: entry.id,
                fieldName: entry.fieldName.trim(),
                fieldDescription: isEmptyStr(entry.fieldDescription)? null: entry.fieldDescription.trim()
            });
        }
        const params = [
            apolloClient,
            setCustomFieldsGql,
            {payload},
            setSubmissionError,
            setIsSubmitting,
            [{query: customFieldsGql, variables: {ownFieldsOnly: true}}]
        ];
        const {error} = await submitMutation(...params);
        if(!error)
        {
            RAlert.close();
            await showNotification(t(`Custom fields applied successfully`));
        }
        if(launchUploadModal)
        {
            launchModal(uploadCustomDataModal, {apolloClient});
        }
    };

    /**
     * Renders card
     */
    const renderCard = (args, prevData, ...next) => {
        return (
            <div className="card borderless shadow-none" style={{height: '100%', ...langDirStyle()}}>
                <div className="card-header">
                    <div className="text-center p-2" style={{fontWeight: 'bolder'}}>
                        {t('Manage Custom Fields')}
                    </div>
                </div>
                <div className="card-body" style={{overflowY: 'auto', overflowX: 'hidden'}}>
                    {getNextRenderer(args, prevData, ...next)}
                </div>
                <div className="card-footer p-3">
                    {/** footer */}
                    <div className="row">
                        <div className="input-group justify-content-center">
                            <button type="button" className="btn btn-outline-secondary" onClick={() => RAlert.close()}>
                                <i className="bx bx-window-close"/>{t('Close')}
                            </button>
                            <button className={`btn btn-success ${isRtl()? 'me-3': 'ms-3'}`} disabled={loading || isSubmitting} onClick={() => onApplyClick(false)}>
                                {
                                    !isSubmitting &&
                                    <>
                                        <i className="bx bx-check-circle"/>
                                        {t('Apply')}
                                    </>
                                }
                                {
                                    isSubmitting &&
                                    <Loading/>
                                }
                            </button>
                            {
                                uploadCustomDataModal !== null &&
                                <button className={`btn btn-success ${isRtl()? 'me-3': 'ms-3'}`} disabled={loading || isSubmitting} onClick={() => onApplyClick(true)}>
                                    <i className="bx bx-check-circle"/>
                                    {t('Apply & upload data')}
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    /**
     * Renders form
     */
    const renderMain = (args, prevData, ...next) => {
        return (
            <div className="animated fadeIn">
                {/* custom fields */}
                {
                    formValues.map((entry, idx) => {
                        return (
                            <div className="form-group p-2" key={`custom-field-${idx}`}>
                                <div className="row">
                                    {/* fieldName */}
                                    <div className="col-md-4" style={{textAlign: 'left'}}>
                                        <label htmlFor={`field-name-${idx}`} className="form-label required">{t('Field name')}</label>
                                        <input type="text" id={`field-name-${idx}`} value={entry.fieldName}
                                               onChange={({target}) => onFieldChange(idx, 'fieldName', target.value)}
                                               className={'form-control' + (!formValidity[idx]?.fieldName? ' is-invalid': '')}/>
                                        {/* remove field */}
                                        <a href={'/'} style={{textDecoration: 'underline'}} onClick={(e) => {
                                            e.preventDefault();
                                            const values = [...formValues];
                                            const validity = [...formValidity];
                                            values.splice(idx, 1);
                                            validity.splice(idx, 1);
                                            setFormValues([...values]);
                                            setFormValidity([...validity]);
                                        }
                                        }><small>{t('Remove field')}</small></a>
                                    </div>
                                    {/* fieldDescription */}
                                    <div className="col-md-4" style={{textAlign: 'left'}}>
                                        <label htmlFor={`field-description-${idx}`} className="form-label">{t('Field description')}</label>
                                        <input type="text" id={`field-description-${idx}`} value={entry.fieldDescription}
                                               onChange={({target}) => onFieldChange(idx, 'fieldDescription', target.value)}
                                               className={'form-control'}/>
                                    </div>
                                </div>
                                <hr/>
                            </div>
                        );
                    })
                }
                {/* add filter */}
                <div className="row">
                    <div className="col-md-12 ms-2 mt-3"  style={{textAlign: isRtl()? 'right': 'left'}}>
                        <a href={'/'} style={{textDecoration: 'underline'}} onClick={(e) => {
                            e.preventDefault();
                            setFormValues([...formValues, {id: null, fieldName: '', fieldDescription: ''}]);
                            setFormValidity([...formValidity, {fieldName: true}]);
                        }
                        }><small>{t('Add field')}</small></a>
                    </div>
                </div>
                {
                    submissionError &&
                    <Error>{submissionError}</Error>
                }
                {getNextRenderer(args, prevData, ...next)}
            </div>
        );
    };
    return (
        renderNestedQueries(
            {},
            {},
            renderCard,
            renderMain
        )
    );
};

export default CustomFieldManagementModal;