import React, {useState} from 'react';
import Error from '../../common/ui/error';
import {getNextRenderer, renderNestedQueries} from "../../common/utils/renderer";
import {useQuery} from "@apollo/client";
import {NO_CACHE} from "../../common/http/options";
import SearchFilter from "../../common/ui/widgets/search";
import {AgGridReact} from "ag-grid-react";
import {getColumns} from './col-definitions';
import getCellRenderers from './cell-renderers';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import '../../common/ui/ag-grid/style.scss';
import NOTIFICATION_GQL from '../viewer/notifications.graphql';
import MARK_NOTIFICATIONS_AS_READ_GQL from './mark-as-read.graphql';
import CLEAR_NOTIFICATIONS_GQL from './clear.graphql';
import {submitMutation} from '../../common/http/mutate';
import Shimmer from "../../common/ui/shimmer";
import {isRtl, langDirStyle, t} from "../../common/translation";

const NotificationManager = ({apolloClient, RAlert}) => {
    const [search, setSearch] = useState('');
    const {data: notificationsData, loading: notificationsLoading, error: notificationsError} = useQuery(NOTIFICATION_GQL, NO_CACHE(apolloClient, {}, false));
    const [isMutating, setIsMutating] = useState(false);
    const [mutationError, setMutationError] = useState(null);

    /**
     * Marks notifications as read / clears notifications
     */
    const onApplyClick = async (gql) => {
        const params = [
            apolloClient,
            gql,
            {lastID: ''},
            setMutationError,
            setIsMutating
        ];
        const {error} = await submitMutation(...params);
        if(!error)
        {
            RAlert.close();
            window.location.reload();
        }
    };

    /**
     * Renders card
     */
    const renderCard = (args, prevData, ...next) => {
        return (
            <div className="card borderless shadow-none" style={{height: '100%', ...langDirStyle()}}>
                <div className="card-header">
                    <div className="text-center p-2" style={{fontWeight: 'bolder'}}>
                        {t('All Notifications')}
                    </div>
                </div>
                <div className="card-body" style={{overflowY: 'auto', overflowX: 'hidden'}}>
                    {getNextRenderer(args, prevData, ...next)}
                </div>
                <div className="card-footer p-3">
                    {/** footer */}
                    <div className="row">
                        <div className="input-group justify-content-center">
                            <button type="button" className={`btn btn-outline-secondary ${isRtl()? 'ms-2': ''}`} onClick={() => RAlert.close()}>
                                <i className="bx bx-window-close"/>{t('Close')}
                            </button>
                            <button type="button" className={`btn btn-outline-dark ${!isRtl()? 'ms-2': ''}`} disabled={isMutating || notificationsLoading} onClick={() => onApplyClick(MARK_NOTIFICATIONS_AS_READ_GQL)}>
                                <i className="bx bx-check"/>{t('Mark all as read')}
                            </button>
                            <button type="button" className={`btn btn-outline-dark ms-2 ${isRtl()? 'me-2': ''}`} disabled={isMutating || notificationsLoading} onClick={() => onApplyClick(CLEAR_NOTIFICATIONS_GQL)}>
                                <i className="bx bx-trash"/>{t('Clear all notifications')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    /**
     * Grid toolbar
     */
    const setupToolbar = (args, prevData, ...next) => {
        return (
            <>
                <div className="row ml-1 mr-1 justify-content-start">
                    <div className="col-md-12">
                        <div className={`input-group ${isRtl()? 'me-2': ''}`}>
                            <SearchFilter notifyOnChange={setSearch} value={search}/>
                        </div>
                    </div>
                </div>
                {getNextRenderer(args, prevData, ...next)}
            </>
        );
    };
    /**
     * Grid setup
     */
    const setupGrid = (args, prevData, ...next) => {
        if(notificationsLoading || isMutating)
        {
            return <Shimmer/>
        }
        if(notificationsError ?? mutationError)
        {
            return <Error>{t('Something went wrong!')}</Error>
        }
        return (
            <>
                <div className="ag-theme-balham" style={{width: '100%', height: '68vh', padding: '10px'}}>
                    <AgGridReact
                        columnDefs={getColumns()}
                        gridOptions={{enableRtl: isRtl()}}
                        frameworkComponents={getCellRenderers()}
                        rowData={(notificationsData?.result ?? []).filter(entry => search.trim() === '' || entry.message.toLocaleLowerCase().search(search.trim().toLocaleLowerCase()) !== -1)}>
                    </AgGridReact>
                </div>
                {getNextRenderer(args, prevData, ...next)}
            </>
        );
    };
    return (
        renderNestedQueries(
            {},
            {},
            renderCard,
            setupToolbar,
            setupGrid
        )
    );
};

export default NotificationManager;