import React from 'react';
import {Link} from 'react-router-dom';

const StateCount = ({count, color, title, deviceState=''}) => {
    return (
        <li className="nav-item dropdown dropdown-large" title={title}>
            <Link to={`/live?deviceState=${deviceState}`} className="nav-link position-relative" role="button" aria-expanded="false">
                <span className="badge rounded-pill" style={{backgroundColor: color}}>
                    <i className="bx bxs-truck"/>
                    {count}
                </span>
            </Link>
        </li>
    );
};

export default StateCount;