/**
 Language selector
 */

import React from 'react';
import '../../../ui/semantic-ui/style.scss';
import SemanticDropdown from "../../../ui/widgets/semantic-dropdown";
import {LANGUAGES} from './languages';
import {t} from "../../../translation";

const LanguageSelector = ({skipEnglish=false, ...props}) => {
    const placeholder = t(`Select language`);
    const customOptions = LANGUAGES
        .filter(entry => skipEnglish === false || entry.code !== 'en')
        .map((entry) => ({key: entry.code, value: entry.code, text: entry.name}))
    return <SemanticDropdown {...props} customOptions={customOptions} placeholder={placeholder} multiple={false}/>
};

export default LanguageSelector;