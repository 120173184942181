/**
 * Displays a confirmation modal
 */

import React, {useState} from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import '../../ag-grid/style.scss';
import Loading from "../../loading";
import {isRtl, t} from "../../../translation";

const ConfirmationModal = ({title, text, onApplyClick, isDangerous, RAlert}) => {
    const [isLoading, setIsLoading] = useState(false);
    /**
     * On click
     */
    const onButtonClick = () => {
        setIsLoading(true);
        onApplyClick(RAlert);
    };
    return (
        <div className="card borderless shadow-none">
            <div className="card-header">
                <div className="text-center p-2" style={{fontSize: '1.875em', color: '#595959', fontWeight: 550}}>
                    {title}
                </div>
            </div>
            <div className="card-body" style={{overflowY: 'auto', overflowX: 'hidden'}}>
                <p dangerouslySetInnerHTML={{__html: text}} style={{textAlign: 'center', direction: isRtl()? 'rtl': 'ltr', fontSize: '1.125em', padding: '0 1.6em', color: '#545454', wordWrap: 'break-word', justifyContent: 'center'}}/>
            </div>
            <div className="card-footer p-3">
                <div className="row">
                    <div className="input-group justify-content-center">
                        <button type="button" className="btn btn-outline-secondary" disabled={isLoading} onClick={() => RAlert.close()}>
                            <i className="bx bx-window-close"/>{t('Cancel')}
                        </button>
                        <button className={`btn btn-${isDangerous? 'danger': 'dark'} ms-3`} disabled={isLoading} onClick={onButtonClick}>
                            {
                                !isLoading &&
                                <>
                                    <i className="bx bx-check-circle"/> {t('Apply')}
                                </>
                            }
                            {
                                isLoading &&
                                <Loading/>
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;