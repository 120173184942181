/**
 Reusable dropdown widget.
 `children` for extended features, ex: create-new link
*/

import React, {useEffect} from 'react';
import {Dropdown} from 'semantic-ui-react';
import '../../semantic-ui/style.scss';
import {langDirText, t, textAlignStyle} from "../../../translation";

const SemanticDropdown = ({
                               selectedValues,
                               options,
                               onValueChange,
                               domID,
                               label,
                               children,
                               placeholder='',
                               multiple=true,
                               displayLabel=true,
                               isDisabled=false,
                               isInvalid=false,
                               customOptions=null,
                               dropdownStyles= {},
                               newSelectionValue=null,
                               setNewSelectionValue=()=>{},
                               ...props}) => {
    /**
     * Auto updates selection with new value created from outside this component, example: on new tracker group
     */
    useEffect(() => {
        if(!!newSelectionValue && !!customOptions)
        {
            if(!customOptions.find(r => r.value === newSelectionValue))
            {
                return;
            }
            setNewSelectionValue(null);
            onValueChange({target: {id: domID, value: multiple? [...selectedValues, newSelectionValue]: newSelectionValue}});
        }
    });
    const style = {
        width: '100%',
        ...textAlignStyle(),
        ...dropdownStyles
    };
    if(isInvalid)
    {
        style.borderColor = '#f41127';
    }
    /**
     * On change
     */
    const onSelectionChange = (data) => {
        onValueChange({target: {id: domID, value: data.value}});
    };
    return (
        <>
            {
                displayLabel &&
                <label className="form-label">{t(label)}</label>
            }
            <Dropdown
                multiple={multiple}
                disabled={isDisabled}
                search
                selection
                closeOnChange
                className={`form-control dropdown-container-text-${langDirText()}`}
                onChange={(event, data) => onSelectionChange(data)}
                style={style}
                options={customOptions ?? options.map(row => ({key: row, value: row, text: row}))}
                value={selectedValues}
                placeholder={placeholder}
                noResultsMessage={t('No results found')}
                {...props}
            />
            {children}
        </>
    );
};

export default SemanticDropdown;