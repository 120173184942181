/**
 * Sets user preference
 */

import SET_PREFERENCES_GQL from './set-preference.graphql';
import USER_PREFERENCES_GQL from './user-preferences.graphql';
import {submitMutation} from "../../http/mutate";

export const setUserPreference = async (apolloClient, settingKey, settingValue) => {
    const params = [
        apolloClient,
        SET_PREFERENCES_GQL,
        {
            payload: {
                [settingKey]: settingValue
            }
        },
        ()=>{},
        ()=>{},
        [{query: USER_PREFERENCES_GQL}]
    ];
    await submitMutation(...params);
};