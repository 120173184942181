/**
 * File uploader
 */

import React from 'react';
import Dropzone from "react-dropzone";
import {submitMutation} from "../../../http/mutate";
import Loading from "../../loading";
import Error from "../../error";
import UPLOAD_IMAGE_GQL from './upload-image.graphql';
import {t} from "../../../translation";

const FileUploader = ({
                          apolloClient,
                          setUploadFileResult,
                          setUploadFileError,
                          setIsUploadingFile,
                          isUploadingFile,
                          uploadFileError,
                          uploadFileResult,
                          label='Upload',
                          UPLOAD_FILE_GQL=UPLOAD_IMAGE_GQL,
                          multiple = false,
                          displayFileName=true
                      }) => {
    /**
     * On upload
     */
    const onFileUpload = async (files) => {
        setUploadFileResult(null);
        const params = [
            apolloClient,
            UPLOAD_FILE_GQL,
            {file: files[0]},
            setUploadFileError,
            setIsUploadingFile
        ];
        const {error, data} = await submitMutation(...params);
        if (!error) {
            setUploadFileResult(data.result);
        } else {
            setUploadFileResult(null);
        }
    };
    return (
        <>
            {
                isUploadingFile && <div className="mt-1" style={{width: '50%'}}><Loading/></div>
            }
            {
                !isUploadingFile &&
                <>
                    <Dropzone onDrop={onFileUpload} multiple={multiple}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()}>
                                <input {...getInputProps()} data-show-remove="false"/>
                                <button type="button" className="btn btn-outline-secondary" style={{height: '55px'}}>
                                    <i className="bx bx-cloud-upload me-0"/>
                                    <span className="ms-1">{t(label)}</span>
                                </button>
                            </div>
                        )}
                    </Dropzone>
                    {
                        (uploadFileResult && displayFileName) &&
                        <div className="form-group p-2">
                            <a href={uploadFileResult.url} target="_blank" rel="noreferrer" style={{textDecoration: 'underline'}}>
                                {uploadFileResult.name}
                            </a>
                            <a href={'/'} className="ms-2 me-2" onClick={(e) => {
                                e.preventDefault();
                                setUploadFileResult(null);
                            }
                            }>{t('remove')}</a>
                        </div>
                    }
                </>
            }
            {
                uploadFileError && <Error>{t(uploadFileError)}</Error>
            }
        </>
    );
};

export default FileUploader;