import React, {useState, useEffect} from 'react';
import {loadExternalScript} from './scripts-loader';
import Loading from '../../common/ui/loading';

/**
 * Loads a Google charts package
 * @param {Array} loadPackage
 * @param {React} children
 */
const GoogleChartsLoader = ({loadPackage, children}) => {
    //renders children on load
    const [isLibsLoaded, setLoadedStatus] = useState(false);
    //this prevents parallel download attempts on fast re-rendering of the invoking component
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if(!isLoading)
        {
            setIsLoading(true);
            loadExternalScript('https://www.gstatic.com/charts/loader.js', () => {
                window.google.charts.load("current", {packages: loadPackage});
                window.google.charts.setOnLoadCallback(() => {
                    setLoadedStatus(true);
                });
            });
        }
    }, [loadPackage, isLoading]);
    return (
        isLibsLoaded ? <>{children}</>: <Loading/>
    );
};

export default React.memo(GoogleChartsLoader);