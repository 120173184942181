/**
 common query options
*/

export const NO_CACHE = (client, variables={}, skip=false, props={}) => {
    const options = {client, variables, ...props};
    options.fetchPolicy = 'no-cache';
    options.notifyOnNetworkStatusChange = true;
    options.skip = skip;
    return options;
};