/**
 Command selector
 */

import React from 'react';
import {useQuery} from '@apollo/client';
import Loading from "../../common/ui/loading";
import Error from '../../common/ui/error';
import COMMANDS_GQL from './commands.graphql';
import '../../common/ui/semantic-ui/style.scss';
import SemanticDropdown from "../../common/ui/widgets/semantic-dropdown";
import {t} from "../../common/translation";

const CommandSelector = (props) => {
    const {data, error, loading} = useQuery(COMMANDS_GQL, {client: props.client});
    if(error) return <Error>{t(error.message)}</Error>;
    if(loading) return <Loading/>;
    const placeholder = t(`Select command`);
    const customOptions = data.result.map((entry) => ({key: entry.id, value: entry.id, text: entry.name}));
    return <SemanticDropdown {...props} customOptions={customOptions} placeholder={placeholder} multiple={false}/>
};

export default CommandSelector;