/**
 Trackers selector
*/

import React from 'react';
import {useQuery} from '@apollo/client';
import TRACKERS_LITE_GQL from './trackers-lite.graphql';
import {Dropdown} from 'semantic-ui-react';
import Loading from "../../loading";
import Error from '../../error';
import '../../semantic-ui/style.scss';
import {langDirText, t} from "../../../translation";

const TrackersSelector = ({filter={}, skip=false, limitToIDs=null, domID='trackerIDs',
                           multiple=true, isDisabled=false, isInvalid=false, onValueChange, selectedValues, ...props}) => {
    const variables = {
        userID: filter?.userID ?? null
    };
    const {data, error, loading} = useQuery(TRACKERS_LITE_GQL, {client: props.client, variables, skip});
    if(error) return <Error>{t(error.message)}</Error>;
    if(loading) return <Loading/>;
    const placeholder = t(`Select ${multiple === false? 'tracker': 'trackers'}`);
    let trackers = !skip? data.result: [];
    //limits selection to predefined list of IDs
    if(limitToIDs && limitToIDs.length > 0)
    {
        trackers = trackers.filter(tracker => limitToIDs.includes(tracker.id));
    }

    /**
     * On change
     */
    const onSelectionChange = (data) => {
        onValueChange({target: {id: domID, value: data.value}});
    };
    const style = {
        width: '100%'
    };
    if(isInvalid)
    {
        style.borderColor = '#f41127';
    }
    return (
        <>
            {
                props.displayLabel &&
                <label className="required">{`Select ${multiple?'trackers': 'tracker'}`}</label>
            }
            <Dropdown
                multiple={multiple}
                disabled={isDisabled}
                search={(items, query) => {
                    return items
                        .filter(item => `${item.label.toLocaleLowerCase()} ${item.text.toLocaleLowerCase()} ${item.description.toLocaleLowerCase()}`.search((query || '').trim().toLocaleLowerCase()) !== -1);
                }}
                selection
                closeOnChange
                className={`form-control dropdown-container-text-${langDirText()}`}
                onChange={(event, data) => onSelectionChange(data)}
                style={style}
                options={trackers.map(row => ({
                    key: row.id,
                    value: row.id,
                    description: row.englishPlateNumber ?? row.plateNumber,
                    label: row.name,
                    text: row.imei
                }))}
                value={selectedValues}
                placeholder={placeholder}
                {...props}
            />
        </>
    );
};

export default React.memo(TrackersSelector);