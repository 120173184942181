/**
 Creates group
*/

import React, {useState} from 'react';
import {isEmptyStr} from "../../../utils/validation";
import Error from '../../../ui/error';
import {submitMutation} from '../../../http/mutate';
import CREATE_GROUP_GQL from './create-group.graphql';
import GROUPS_GQL from './groups.graphql';
import Loading from "../../loading";
import {isDistributor} from "../../../auth/guard/check";
import {isRtl, t} from "../../../translation";

const NewTrackerGroup = ({client, setIsCreateGroupVisible, setNewSelectionValue, refresh, setRefresh, recordOnEdit=null, userID=null}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [groupName, setGroupName] = useState(recordOnEdit?.groupName ?? '');
    /**
     * On apply click
     */
    const onApplyClick = async () => {
        if(isEmptyStr(groupName))
        {
            return;
        }
        const variables = {payload: {name: groupName.trim(), userID: isDistributor()? userID: null}};
        if(recordOnEdit?.groupID)
        {
            variables.groupID = recordOnEdit.groupID;
        }
        const params = [
            client,
            CREATE_GROUP_GQL,
            variables,
            setErrorMessage,
            setIsLoading,
            [{query: GROUPS_GQL, variables: {filter: {userID}}}]
        ];
        const {error, data: groupMutationData} = await submitMutation(...params);
        if(!error)
        {
            setRefresh(refresh + 1);
            if(!recordOnEdit)
            {
                //convert to string to match groupID of type ID returned from query
                setNewSelectionValue(groupMutationData.result + '');
            }
            setIsCreateGroupVisible(false);
        }
    };
    /**
     * On enter
     */
    const onKeyDown = async (evt) => {
        if (evt.key === 'Enter') {
            evt.preventDefault();
            await onApplyClick();
        }
    };

    return (
        <div className="card mt-2">
            <div className="form-group p-2">
                {
                    errorMessage &&
                    <Error>{t(errorMessage)}</Error>
                }
                <div className="row">
                    <div className="col-md-8" style={{textAlign: 'left'}}>
                        <input type="text" value={groupName} onChange={({target}) => setGroupName(target.value)}
                               placeholder={t('Group name')} className={'form-control'} autoFocus={true} onKeyDown={onKeyDown}/>
                    </div>
                    <div className="col-md-4" style={{textAlign: 'left'}}>
                        <div className="input-group">
                            <button type="button" className={`btn btn-outline-secondary ${isRtl()? 'ms-1': ''}`} disabled={isLoading} onClick={() => setIsCreateGroupVisible(false)} style={{height: '55px'}}>
                                <i className="bx bx-window-close"/>
                            </button>
                            <button className={`btn btn-success ${!isRtl()? 'ms-1': ''}`} disabled={isLoading} onClick={() => onApplyClick()} style={{height: '55px'}}>
                                {
                                    !isLoading &&
                                    <>
                                        <i className="bx bx-check-circle"/>
                                    </>
                                }
                                {
                                    isLoading &&
                                    <Loading/>
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewTrackerGroup;