import React from 'react';
import commonCellRenderers from '../common/ui/ag-grid/cell-renderers';
import COLORS from '../common/ui/colors';
import {t} from "../common/translation";
import {isDistributor, isUser} from "../common/auth/guard/check";
import CopyToClipboard from "../common/ui/widgets/copy-to-clipboard";
import {CLIENT_DATE_FORMAT, getCurrentJsDate, getLocalDbDate} from "../common/utils/date";

const cellRenderers = () => ({
    ...commonCellRenderers,
    name: (node) => {
        let distValue = node.value;
        let isExpired = false;
        if(node.data.license.expiresOn !== null && getLocalDbDate(node.data.license.expiresOn, CLIENT_DATE_FORMAT) < getCurrentJsDate())
        {
            isExpired = true;
            distValue = <span style={{color: COLORS.RED}}>{node.value} (EXPIRED)</span>
        }
        const distLink = <span>
            <a className="me-1" href={`/user-impersonation-token?userID=${node.data.user.id}&redirectTo=${encodeURIComponent(`/live/track?trackerID=${node.data.id}`)}`} target="_blank" rel="noreferrer noopener">{distValue}</a>
            <CopyToClipboard content={node.value}/>
        </span>;
        const userLink = <span>
            <a className="me-1" href={`/live/track?trackerID=${node.data.id}`}>{node.value}</a>
            <CopyToClipboard content={node.value}/>
        </span>;
        if(isExpired && isDistributor())
        {
            return commonCellRenderers.boldText({value: distLink});
        }
        let targetLink;
        if(isUser())
        {
            targetLink = userLink;
        }
        else if(isDistributor(['sign-in-as-users']))
        {
            targetLink = distLink;
        }
        else {
            targetLink = <span>
                {node.value}
                <CopyToClipboard content={node.value}/>
            </span>;
        }
        return commonCellRenderers.centerText({value: targetLink});
    },
    imei: (node) => {
        if(!node.data.isActive)
        {
            return commonCellRenderers.boldText({value: <span style={{color: COLORS.RED, textDecoration: 'line-through'}}>{node.value}</span>});
        }
        if(node.data.user.state !== 'blocked')
        {
            return commonCellRenderers.centerTextWithCopyToClipboard(node);
        }
        return commonCellRenderers.boldText({value: <span style={{color: COLORS.RED}}>{node.value}</span>});
    },
    licensePlate: (node) => {
        return commonCellRenderers.centerTextWithCopyToClipboard({value: node.data.englishPlateNumber ?? node.data.plateNumber});
    },
    trackerType: (node) => {
        return commonCellRenderers.centerText({value: `${node.value.family.familyName} (${node.value.typeName})`});
    },
    groups: (node) => {
        return commonCellRenderers.arrayRenderer({value: node.value.map(r => r.groupName)});
    },
    licenseState: (node) => {
        let color = COLORS.GREEN;
        if(node.value === 'pending')
        {
            color = COLORS.ORANGE;
        }
        else if(node.value === 'expired')
        {
            color = COLORS.RED;
        }
        return commonCellRenderers.boldText({value: <span style={{color}}>{t(node.value.toUpperCase())}</span>});
    },
    sensors: (node) => {
        return commonCellRenderers.booleanRenderer({value: (node.data.sensors ?? []).includes(node.colDef.field)});
    },
    isNWCEligible: (node) => {
        if(!node.value?.length)
        {
            return commonCellRenderers.centerText({value: 'N/A'});
        }
        return commonCellRenderers.booleanRenderer({value: !!(node.value ?? []).find(r => r.isNWCEligible === true)});
    },
    jeddahMunicipality: (node) => {
        return commonCellRenderers.booleanRenderer({value: node.value?.jeddahMunicipality?.integratedOn});
    }
});

export default cellRenderers;