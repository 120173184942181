/**
 Submits a mutation
*/

export const submitMutation = async (client, mutation, variables, setErrorMessage=()=>{}, setIsLoading=()=>{}, refetchQueries=null) => {
    setErrorMessage(null);
    setIsLoading(true);
    let _error = null;
    let _data = null;
    try {
        const options = {
            mutation,
            variables,
            errorPolicy: 'all'
        };
        if(refetchQueries)
        {
            options.refetchQueries = refetchQueries;
            options.awaitRefetchQueries = true;
        }
        const {errors, data} = await client.mutate(options);
        if(errors)
        {
            _error = errors[0].message;
        }
        _data = data;
    }
    catch (e) {
        _error = e.message;
    }
    finally {
        if(_error)
        {
            setErrorMessage(_error);
        }
        setIsLoading(false);
    }
    return {error: _error, data: _data};
};