export const fields = [
    'country',
    'utcOffsetHours',
    'languageCode',
    'initialCoordinates',
    'initialZoomLevel',
    'defaultMapDisplay',
    'showLiveTraffic',
    'showMapPointsOfInterest',
    'followTracker',
    'displayLandmarksInLiveView',
    'displayGeofencesInLiveView',
    'trackerLiveTripStrokeColor',
    'contactName',
    'contactMobile',
    'password',
    'confirmPassword'
];