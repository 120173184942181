export const DEFAULT_MAP_SETTINGS = {
    GOOGLE_API_LIBRARIES: ['drawing', 'geometry', 'visualization'],
    ACTIVE_TRACKER_ZOOM: 17,
    TRIP_STROKE_OPACITY: 1,
    TRIP_STROKE_WEIGHT: 4,
    //minimum number of seconds on which to display stopping/idling clocks in tracker' trip route
    DISPLAY_TRACKER_STOP_CLOCK_ON_MIN_SECS: 10,
    DISPLAY_TRACKER_IDLING_CLOCK_ON_MIN_SECS: 10,
    //fetch interval of last packet & tracker's trip
    FETCH_INTERVAL_MS: 10000
};