/**
 Country selector
 */

import React from 'react';
import '../../../ui/semantic-ui/style.scss';
import SemanticDropdown from "../../../ui/widgets/semantic-dropdown";
import {COUNTRIES} from './countries';
import {t} from "../../../translation";

const CountrySelector = (props) => {
    const placeholder = t(`Select country`);
    const customOptions = COUNTRIES.map((entry) => ({key: entry.code, value: entry.code, text: entry.country}))
    return <SemanticDropdown {...props} customOptions={customOptions} placeholder={placeholder} multiple={false}/>
};

export default CountrySelector;