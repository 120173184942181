import React from 'react';
import ReactDOM from 'react-dom';
import {ApolloClient, ApolloProvider, ApolloLink} from '@apollo/client';
import {RetryLink} from '@apollo/client/link/retry';
import {onError} from '@apollo/client/link/error';
import {cache} from './cache';
import AuthController from "./common/auth/controller";
import {createUploadLink} from 'apollo-upload-client';
import * as serviceWorker from './serviceWorker';
import App from "./app";
import UserPreferencesAndTranslation from "./common/user/settings/loader";

/**
 * Initialize Apollo client
 */
const linkOptions = {
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT_URI.replace('%host', window.location.hostname),
    fetch: (uri, options) => {
        options.headers['x-token'] = sessionStorage.getItem('impersonationToken') ?? localStorage.getItem('token');
        return fetch(uri, options);
    }
};

/**
 * Retries
 * @type {RetryLink}
 */
const retryLink = new RetryLink({
    attempts: {
        max: 10,
        retryIf: (error, _operation) => !!error,
        delay: {
            initial: 300,
            max: Infinity,
            jitter: true,
            factor: 2
        }
    }
});

/**
 * JWT errors / expiry
 * @type {ApolloLink}
 */
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
        for (let err of graphQLErrors) {
            if(err.message === 'jwt expired' || err.message === 'invalid signature')
            {
                localStorage.removeItem('token');
                sessionStorage.removeItem('impersonationToken');
                // reload to display sign-in page
                window.location.reload();
                break;
            }
        }
    }
    // Handle other errors or pass them down the chain
    return forward(operation);
});

const link = ApolloLink.from([retryLink, errorLink, createUploadLink(linkOptions)]);

const client = new ApolloClient({
    cache,
    link
});

ReactDOM.render(
    <ApolloProvider client={client}>
        <AuthController>
            <UserPreferencesAndTranslation>
                <App/>
            </UserPreferencesAndTranslation>
        </AuthController>
    </ApolloProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
