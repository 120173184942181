/**
 Validation utilities
*/
import moment from 'moment';
import {DB_DATE_FORMAT, DB_DATE_TIME_FORMAT} from "./date";

/**
 * Is valid email address
 * @param email
 * @return {boolean}
 */
export const isValidEmail = email => {
    //regex from https://html5-tutorial.net/form-validation/validating-email/
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
};

/**
 * Date-pattern validation
 * @param dateStr
 */
export const isValidDatePattern = dateStr => {
    return /^\d{4}-\d{2}-\d{2}$/.test(dateStr);
};
/**
 * Validates a date of the format 2019-01-22 is valid
 * @param dateStr
 */
export const isValidDate = dateStr => {
    return isValidDatePattern(dateStr) && moment(dateStr, DB_DATE_FORMAT).isValid();
};
/**
 * Validates a date of the format 2019-01-22 13:22:00 is valid
 * @param dateStr
 */
export const isValidDateTime = dateStr => {
    return /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(dateStr) && moment(dateStr, DB_DATE_TIME_FORMAT).isValid();
};
/**
 * Validates hour
 */
export const isValidHour = hour => {
    return /^\d+$/.test(hour) && parseInt(hour) >= 0 && parseInt(hour) < 24;
};
/**
 * Validates minute
 */
export const isValidMinutes = minutes => {
    return /^\d+$/.test(minutes) && parseInt(minutes) >= 0 && parseInt(minutes) < 60;
};
/**
 * Validates hh:mm, example: 13:22
 */
export const isValidTimeSegment = segment => {
    return /^\d{2}:\d{2}$/.test(segment ?? '') && isValidHour(segment.split(':')[0]) && isValidMinutes(segment.split(':')[1]);
};

/**
 * Is valid int
 */
export const isValidInt = (value, allowNegatives=false) => {
    if(!allowNegatives)
    {
        return /^\d+$/.test(value + '');
    }
    return /^-?\d+$/.test(value + '');
};

/**
 * Is valid float
 */
export const isValidFloat = value => {
    return /^-?(\d+)?(\.)?\d+$/.test(value + '') && !isNaN(parseFloat(value + ''));
};

/**
 * Is empty string
 */
export const isEmptyStr = value => {
    return (value + '').trim() === '';
};
/**
 * Validates lat or lng
 */
export const isValidLatOrLng = (latOrLng) => {
    return /^-?\d{2,3}\.\d{3,}$/.test(latOrLng);
};

/**
 * Validates phone is in i18n format
 */
export const isValidPhone = (phone) => {
    return /^\+\d{11,20}$/.test(phone || '');
};

/**
 * Verifies the format of Hijri dates
 */
export const isValidHijriDate = (value) => {
    return /^\d{4}-\d{2}-\d{2}$/.test(value || '');
};