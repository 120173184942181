/**
 SweetAlert modal
 */
import React from 'react';
import SAlert from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import withReactContent from 'sweetalert2-react-content'
import './styles.scss';

const RAlert = withReactContent(SAlert);

const SweetAlertModal = (ModalContent, props, options) => {
    if(!props)
    {
        props = {};
    }
    if(!options)
    {
        options = {};
    }
    //pass the instance to the component
    props.RAlert = RAlert;
    const modalOptions = {
        title: '',
        width: options.width ?? 800,
        html: <ModalContent {...props}/>,
        showCancelButton: false,
        showConfirmButton: false,
        allowEscapeKey : options.allowEscapeKey ?? true,
        allowOutsideClick: options.allowOutsideClick ?? true,
        customClass: {
            content: 'sweet-alert-custom-content',
            popup: 'sweet-alert-custom-popup'
        }
    };
    if(options.fullscreen)
    {
        modalOptions.width = '100%';
        modalOptions.customClass = {
            content: 'sweet-alert-custom-content-fullscreen',
            popup: 'sweet-alert-custom-popup-fullscreen',
            htmlContainer: 'sweet-alert-custom-content-fullscreen',
            container: 'sweet-alert-custom-content-fullscreen'
        };
    }
    RAlert.fire(modalOptions);
};

export default SweetAlertModal;