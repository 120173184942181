/**
 Loading indicator
 */

import React from 'react';

const Loading = ({children}) => {
    return (
        <div className="d-flex justify-content-center">
            <div className="spinner-grow" data-testid="loading-spinner"/>
            {
                children && <span className="text-muted" style={{marginTop: '5px'}}>{children}</span>
            }
        </div>
    );
};

export default Loading;