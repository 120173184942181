import {t} from "../../common/translation";

export const getColumns = () => {
    return [
        {
            headerName: t('Notification'),
            field: 'message',
            cellRenderer: 'centerText',
            resizable: true,
            width: 350
        },
        {
            headerName: t('Time'),
            field: 'notificationTime',
            cellRenderer: 'notificationTime',
            resizable: true
        },
        {
            headerName: t('High Priority'),
            field: 'isHighPriority',
            cellRenderer: 'plainBooleanRenderer',
            resizable: true
        }
    ];
};