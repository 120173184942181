/**
 Auth controller
 */

import React, {useState, useEffect} from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import {useApolloClient, useReactiveVar} from "@apollo/client";
import SIGN_IN_GQL from './sign-in.graphql';
import VERIFY_TOKEN_GQL from './verify-token.graphql';
import SETUP_ACCOUNT_MIGRATION_GQL from './setup-account-migration.graphql';
import {submitMutation} from '../http/mutate';
import LoginForm from "./login-form";
import {isEmptyStr, isValidEmail} from '../utils/validation';
import {accountVar} from "./cache";
import Shimmer from "../ui/shimmer";

/**
 * Firebase config
 */
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};
if(!firebase.apps.length)
{
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
}

/**
 * Flag set when the login is submitted
 * @type {boolean}
 */
let IS_SIGN_IN = false;

const AuthController = ({children}) => {
    const client = useApolloClient();
    const accountVarState = useReactiveVar(accountVar);
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmittingCredentials, setIsSubmittingCredentials] = useState(false);
    const [isInvalidCredentials, setIsInvalidCredentials] = useState(false);

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                firebase.auth().currentUser.getIdToken(true).then(async () => {
                    if(IS_SIGN_IN)
                    {
                        //older versions of Firebase used to trigger this callback on sign in!
                        return;
                    }
                    const jwtToken = sessionStorage.getItem('impersonationToken') ?? localStorage.getItem('token');
                    if(!jwtToken)
                    {
                        setIsLoading(false);
                    }
                    else {
                        const {error, data} = await submitMutation(client, VERIFY_TOKEN_GQL, {jwtToken});
                        if(!!error)
                        {
                            setIsLoading(false);
                        }
                        else {
                            setIsLoading(false);
                            accountVar(data.result);
                        }
                    }
                }).catch((error) => {
                    setIsLoading(false);
                    console.error(`getIdToken error: ${error}`);
                });
            } else {
                setIsLoading(false);
            }
        });
    }, [client]);

    /**
     * onLoginFormSubmit
     */
    const onLoginFormSubmit = async (email, password) => {
        IS_SIGN_IN = true;
        if (isEmptyStr(email) || isEmptyStr(password)) {
            return false;
        }
        password = password.trim();
        setIsSubmittingCredentials(true);
        try {
            if(!isValidEmail(email) && password.length >= 6)
            {
                const {error: migrationError, data: migrationData} = await submitMutation(client, SETUP_ACCOUNT_MIGRATION_GQL, {username: email, password});
                if(!!migrationError)
                {
                    setIsSubmittingCredentials(false);
                    setIsInvalidCredentials(true);
                }
                else {
                    if(migrationData.result !== null)
                    {
                        email = migrationData.result;
                    }
                }
            }
            await firebase.auth().signInWithEmailAndPassword(email, password);
            const firebaseToken = await firebase.auth().currentUser.getIdToken(true);
            const {error, data} = await submitMutation(client, SIGN_IN_GQL, {firebaseToken});
            if(!!error)
            {
                setIsSubmittingCredentials(false);
                setIsInvalidCredentials(true);
            }
            else {
                localStorage.setItem('token', data.result.jwtToken);
                accountVar(data.result);
            }
        } catch (e) {
            setIsSubmittingCredentials(false);
            setIsInvalidCredentials(true);
            console.error(`signInWithEmailAndPassword error: ${e.message}`);
        }
    };
    return (
        <>
            {
                isLoading && <div style={{paddingTop: '15%'}}><Shimmer/></div>
            }
            {
                !isLoading && accountVarState === null &&
                <LoginForm onLogin={onLoginFormSubmit} isSubmittingCredentials={isSubmittingCredentials}
                           isInvalidCredentials={isInvalidCredentials}/>
            }
            {
                !isLoading && !!accountVarState && children
            }
        </>
    );
};

export default AuthController;

