/**
 * Translator
 */
import {languageTranslationVar} from './cache';
import {userPreferencesVar} from "../user/settings/cache";

/**
 * Text translation
 */
export const t = (text) => {
    if(typeof text !== 'string')
    {
        return text;
    }
    const value = text.toLocaleLowerCase().trim();
    const translation = languageTranslationVar()?.[value];
    if(translation !== undefined)
    {
        return translation;
    }
    return text;
};

/**
 * Example: {direction: 'rtl'}
 */
export const langDirStyle = () => {
    return {
        direction: userPreferencesVar()?.languageDirection ?? 'ltr'
    };
};

/**
 * True on RTL languages
 */
export const isRtl = () => {
    return userPreferencesVar()?.languageDirection === 'rtl';
};

/**
 * Example: {textAlign: 'left'}
 */
export const textAlignStyle = () => {
    return {
        textAlign: userPreferencesVar()?.languageDirection === 'rtl'? 'right': 'left'
    };
};

/**
 * 'left' or 'right'
 */
export const langDirText = () => {
    return userPreferencesVar()?.languageDirection === 'rtl'? 'right': 'left';
};