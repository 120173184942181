import commonCellRenderers from '../../common/ui/ag-grid/cell-renderers';
import {DB_DATE_TIME_FORMAT, getUserDateTime} from "../../common/utils/date";

const cellRenderers = () => ({
    ...commonCellRenderers,
    notificationTime: (node) => {
        return getUserDateTime(node.value, DB_DATE_TIME_FORMAT);
    }
});

export default cellRenderers;