import React from 'react';
import {isRtl} from "../../translation";
import MobileAppLinks from "../../mobile-app-links";

const Footer = () => {
    return (
        <footer className={`page-footer app-direction-${isRtl()? 'rtl': 'ltr'}`}>
            <div className="mb-0 mt-1">
                <span>© {new Date().getFullYear()} Saudi Tracking Solutions</span>
                <div style={{float: 'right', paddingRight: '20px'}}>
                    <MobileAppLinks/>
                </div>
            </div>
        </footer>
    );
};

export default Footer;