import React, {useEffect} from "react";
import TimeRange from "../slider";
import {useReactiveVar} from "@apollo/client";
import {drawPlaybackTimeSlider, setPlaybackTimeSliderIndex} from "./cache";
import {COLOR_MAP} from '../../live-tracking/config/state-color';
import moment from 'moment';
import {DB_DATE_TIME_FORMAT} from '../../common/utils/date';
import {accountVar} from "../../common/auth/cache";
import {setPlaybackAnimationOnOff, setPlaybackTripIndex} from "../history/cache";

/**
 * User's datetime
 */
const getDate = (value) => {
    return moment(value, DB_DATE_TIME_FORMAT).add(accountVar().timezoneOffsetSecs, 'second').toDate();
};

const PlaybackTimeSlider = () => {
    const playbackTimeSliderState = useReactiveVar(drawPlaybackTimeSlider);

    /**
     * Reset on exit
     */
    useEffect(() => {
        return () => {
            drawPlaybackTimeSlider(null);
        };
    }, []);

    if(!playbackTimeSliderState?.length)
    {
        return null;
    }
    /**
     * Build three entries from each edge: state, start & end times
     * @type {*[]}
     */
    const points = [];
    let lastDeviceState = null;
    for(const entry of playbackTimeSliderState)
    {
        if(lastDeviceState !== entry.deviceState)
        {
            if(lastDeviceState !== null)
            {
                //end time
                points.push(entry.deviceTime);
            }
            //state
            points.push(entry.deviceState);
            //start time
            points.push(entry.deviceTime);
            lastDeviceState = entry.deviceState;
        }
    }
    points.push(playbackTimeSliderState[playbackTimeSliderState.length - 1].deviceTime);
    /**
     * Group edges
     */
    const interval = [];
    for(let idx=0; idx < points.length; idx+=3)
    {
        const state = points[idx];
        interval.push({
            color: COLOR_MAP[state],
            start: getDate(points[idx + 1]),
            end: getDate(points[idx + 2])
        });
    }
    /**
     * Normalize intervals, fill gaps with stoppage
     */
    const normalizedInterval = [];
    for(let idx=0; idx < interval.length; idx++)
    {
        if(idx > 0 && interval[idx].start > interval[idx - 1].end)
        {
            normalizedInterval.push({
                start: interval[idx - 1].end,
                end: interval[idx].start,
                color: COLOR_MAP.s
            });
        }
        normalizedInterval.push(interval[idx]);
    }

    return (
        <TimeRange
            error={false}
            ticksNumber={36}
            timelineInterval={[normalizedInterval[0].start, normalizedInterval[normalizedInterval.length - 1].end]}
            onUpdateCallback={() => {}}
            onChangeCallback={() => {}}
            disabledIntervals={normalizedInterval}
            onTrackClick={(startTime) => {
                let counter = 0;
                for(const entry of playbackTimeSliderState)
                {
                    if(getDate(entry.deviceTime) >= startTime)
                    {
                        setPlaybackTimeSliderIndex(counter);
                        const animationState = setPlaybackAnimationOnOff();
                        const tripIndex = setPlaybackTripIndex();
                        if(!animationState[tripIndex])
                        {
                            setPlaybackAnimationOnOff({...animationState, [tripIndex]: true});
                        }
                        break;
                    }
                    counter += 1;
                }
            }}
        />
    );
};

export default React.memo(PlaybackTimeSlider);