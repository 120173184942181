import React, {useState} from 'react';
import {isRtl, t, textAlignStyle} from "../../../common/translation";
import Loading from "../../../common/ui/loading";
import COMMANDS_GQL from '../commands.graphql';
import RENAME_GQL from './rename.graphql';
import DELETE_GQL from './delete.graphql';
import {useQuery} from "@apollo/client";
import {submitMutation} from "../../../common/http/mutate";
import Error from "../../../common/ui/error";

const CommandRenameDelete = ({apolloClient, commandID, setSuccessMessage, resetForm}) => {
    const [action, setAction] = useState('rename');
    const {data: command} = useQuery(COMMANDS_GQL, {client: apolloClient, variables: {commandID}});
    const [commandName, setCommandName] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    if(commandName === '' && !!command?.result?.[0]?.name)
    {
        setCommandName(command.result[0].name);
    }

    /**
     * On enter
     */
    const onKeyDown = async (evt) => {
        if (evt.key === 'Enter') {
            evt.preventDefault();
            await onApplyClick();
        }
    };

    /**
     * On apply
     */
    const onApplyClick = async () => {
        if(action === 'rename' && commandName.trim() === '')
        {
            return;
        }
        setErrorMessage(null);
        setSuccessMessage(null);
        setIsLoading(true);
        const params = [
            apolloClient,
            action === 'rename'? RENAME_GQL: DELETE_GQL,
        ];
        if(action === 'rename')
        {
            params.push({commandID, name: commandName.trim()});
        }
        else {
            params.push({commandID});
        }
        params.push(setErrorMessage);
        params.push(setIsLoading);
        const reFetchQueries = [{query: COMMANDS_GQL}];
        if(action === 'rename')
        {
            reFetchQueries.push({query: COMMANDS_GQL, variables: {commandID}});
        }
        params.push(reFetchQueries);
        const {error} = await submitMutation(...params);
        if(!error)
        {
            if(action === 'rename')
            {
                setSuccessMessage(t('Command renamed successfully'));
            }
            else {
                setSuccessMessage(t('Command deleted successfully'));
                resetForm();
            }
        }
    };

    return (
        <>
            <div className="form-group p-2">
                <div className="row">
                    <div className="col-md-4" style={textAlignStyle()}>
                        <label htmlFor="action" className="form-label required">{t('Select action')}</label>
                        <select id={'action'} className={'form-control'}
                                onChange={({target}) => setAction(target.value)} value={action}>
                            <option value={'rename'}>{t('Rename')}</option>
                            <option value={'delete'}>{t('Delete')}</option>
                        </select>
                    </div>
                    {
                        action === 'rename' &&
                        <>
                            <div className="col-md-4" style={textAlignStyle()}>
                                <label htmlFor="action" className="form-label required">{t('Name')}</label>
                                <div className="input-group">
                                    <input type="text" value={commandName} onChange={({target}) => setCommandName(target.value)}
                                           className={'form-control'} autoFocus={true} onKeyDown={onKeyDown}/>
                                    <button className={`btn btn-success ${!isRtl()? 'ms-1': ''}`} disabled={isLoading} onClick={() => onApplyClick()} style={{height: '55px'}}>
                                        {
                                            !isLoading &&
                                            <>
                                                <i className="bx bx-check-circle"/>
                                            </>
                                        }
                                        {
                                            isLoading &&
                                            <Loading/>
                                        }
                                    </button>
                                </div>
                            </div>
                        </>
                    }
                    {
                        action === 'delete' &&
                        <>
                            <div className="col-md-4" style={textAlignStyle()}>
                                <label htmlFor="action" className="form-label required">{t('Confirm removal')}</label>
                                <button className={`btn btn-danger form-control`} disabled={isLoading} onClick={() => onApplyClick()}>
                                    {
                                        !isLoading &&
                                        <>
                                            <i className="bx bx-check-circle"/>
                                            {t('Confirm')}
                                        </>
                                    }
                                    {
                                        isLoading &&
                                        <Loading/>
                                    }
                                </button>
                            </div>
                        </>
                    }
                </div>
            </div>
            {
                errorMessage !== null &&
                <div className="form-group p-2">
                    <div style={{...textAlignStyle()}}>
                        <Error>{errorMessage}</Error>
                    </div>
                </div>
            }
        </>
    );
};

export default CommandRenameDelete;