import React from 'react';
import TopbarOptionsMenu from "../../live-tracking/topbar/options-menu";
import PlaybackTimeSlider from "../slider/time-range";

const PlaybackTopbar = () => {
    return (
        <React.Fragment>
            <div className="flex-grow-1">
                <PlaybackTimeSlider/>
            </div>
            <div className="top-menu ms-auto">
                <ul className="navbar-nav align-items-center">
                    <TopbarOptionsMenu/>
                </ul>
            </div>
        </React.Fragment>
    );
};

export default PlaybackTopbar;