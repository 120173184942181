import React from 'react';
import './app-stores.css';

const MobileAppLinks = () => {
    return (
        <React.Fragment>
            <a href="https://apps.apple.com/ae/app/saudi-tracking-solutions/id1611971970" target="_blank" rel="noreferrer noopener" className="market-btn apple-btn" role="button">
                <span className="market-button-title">App Store</span>
            </a>
            &nbsp;
            <a href="https://play.google.com/store/apps/details?id=com.sactracking.app&hl=en&gl=US" target="_blank" rel="noreferrer noopener" className="market-btn google-btn" role="button">
                <span className="market-button-title">Google Play</span>
            </a>
        </React.Fragment>
    );
};

export default MobileAppLinks;