/**
 Uploads data for custom-fields
 */

import React, {useState} from 'react';
import Shimmer from "../../common/ui/shimmer";
import IMPORT_CUSTOM_FIELDS_Gql from './import.graphql';
import FileUploader from "../../common/ui/widgets/uploader";
import {isRtl, langDirStyle, t, textAlignStyle} from "../../common/translation";
import Loading from "../../common/ui/loading";
import GENERATE_TEMPLATE_GQL from './generate-template.graphql';
import Error from "../../common/ui/error";
import {submitMutation} from "../../common/http/mutate";

const CustomFieldDataUploadModal = ({apolloClient, RAlert}) => {
    const [templateResult, setTemplateResult] = useState(null);
    const [isGeneratingTemplate, setIsGeneratingTemplate] = useState(false);
    const [generateTemplateError, setGenerateTemplateError] = useState(null);
    const [isUploadingFile, setIsUploadingFile] = useState(false);
    const [uploadFileError, setUploadFileError] = useState(null);
    const [uploadFileResult, setUploadFileResult] = useState(null);

    /**
     * On generate template
     */
    const onGenerateTemplate = async (e) => {
        e.preventDefault();
        const params = [
            apolloClient,
            GENERATE_TEMPLATE_GQL,
            {},
            setGenerateTemplateError,
            setIsGeneratingTemplate
        ];
        const {data} = await submitMutation(...params);
        setTemplateResult({result: data.result});
    };

    return (
        <div className="card" style={{fontSize: '18px', marginBottom: 0}}>
            <div className="card-header">
                <div className="text-center p-2" style={{fontWeight: 'bolder'}}>
                    {t('Upload Data for Custom Fields')}
                </div>
            </div>
            <div className="card-body" style={{...langDirStyle()}}>
                {
                    isUploadingFile &&
                    <Shimmer/>
                }
                {
                    !isUploadingFile &&
                    <div style={textAlignStyle()}>
                        <ul>
                            {
                                templateResult === null && isGeneratingTemplate === false &&
                                <>
                                    <li>
                                        {t('First, ')} <a href="/" onClick={onGenerateTemplate} style={{textDecoration: 'underline'}}>
                                            {t('generate template')}
                                        </a>
                                        {t('. This will create an Excel with all of your landmarks and custom fields.')}
                                    </li>
                                </>
                            }
                            {
                                isGeneratingTemplate &&
                                <>
                                    <Loading/>
                                </>
                            }
                            {
                                templateResult?.result === null &&
                                <li style={{marginTop: '10px'}}>
                                    {t('You need to create your custom fields first, see the tool "Manage custom fields"')}
                                </li>
                            }
                            {
                                generateTemplateError !== null &&
                                <>
                                    <li>
                                        <Error>{t('Something went wrong!')}</Error>
                                    </li>
                                </>
                            }
                            {
                                !!templateResult?.result &&
                                <>
                                    <li>
                                        <a href={templateResult?.result} style={{textDecoration: 'underline'}}>
                                            {t('Download template')}
                                        </a>
                                        {t(`. Fill custom values for any number of landmarks. Once you're done, click on "Import" and upload your Excel.`)}
                                    </li>
                                </>
                            }
                        </ul>
                        {
                            !!templateResult?.result &&
                            <div className={`mt-3 ms-3 ${isRtl()? ' me-3': ''}`}>
                                <FileUploader apolloClient={apolloClient} setUploadFileResult={setUploadFileResult} setUploadFileError={setUploadFileError}
                                              setIsUploadingFile={setIsUploadingFile} isUploadingFile={isUploadingFile} uploadFileError={uploadFileError}
                                              uploadFileResult={uploadFileResult} UPLOAD_FILE_GQL={IMPORT_CUSTOM_FIELDS_Gql} label={'Import'} displayFileName={false}/>
                            </div>
                        }
                        {
                            uploadFileResult !== null &&
                            <div className="mt-2">
                                <hr/>
                                {
                                    uploadFileResult === 0 &&
                                    <div className="ms-3 mt-3">
                                        <i className="bx bx-info-circle me-1"/> {t('No rows were imported!')}
                                    </div>
                                }
                                {
                                    uploadFileResult > 0 &&
                                    <div className="ms-3 mt-3">
                                        <i className="bx bx-check-circle me-1" style={{color: 'green'}}/>
                                        {t(`Successfully imported or updated {COUNT} record${uploadFileResult > 1? 's': ''}`).replace('{COUNT}', uploadFileResult)}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
            </div>

            <div className="card-footer p-3">
                <div className="row">
                    <div className="input-group justify-content-center">
                        <button type="button" className="btn btn-outline-secondary" disabled={isUploadingFile} onClick={() => RAlert.close()}>
                            <i className="bx bx-window-close"/>{t('Close')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomFieldDataUploadModal;