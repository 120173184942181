import React from 'react';
import {useQuery, useApolloClient} from '@apollo/client';
import StateCount from "./state-count";
import STATE_COUNT_GQL from './tracker-state-count.graphql';
import Loading from "../../common/ui/loading";
import Error from "../../common/ui/error";
import {ALL, DISCONNECTED, IDLING, MOVING, STOPPED} from "../config/state-color";
import TopbarOptionsMenu from "./options-menu";
import {t} from "../../common/translation";

const LiveTrackingTopbar = () => {
    const apolloClient = useApolloClient();
    const {data, error, loading} = useQuery(STATE_COUNT_GQL, {client: apolloClient, pollInterval: 15000});
    if(error)
    {
        return <Error>{t(error.message)}</Error>
    }
    if(loading)
    {
        return <Loading/>
    }
    return (
        <div className="top-menu ms-auto">
            <ul className="navbar-nav align-items-center">
                <TopbarOptionsMenu/>
                <StateCount count={data.result.moving} color={MOVING} title={t('Moving')} deviceState={'m'}/>
                <StateCount count={data.result.idling} color={IDLING} title={t('Idling')} deviceState={'i'}/>
                <StateCount count={data.result.stopped} color={STOPPED} title={t('Stopped')} deviceState={'s'}/>
                <StateCount count={data.result.disconnected} color={DISCONNECTED} title={t('Disconnected')} deviceState={'d'}/>
                <StateCount count={data.result.total} color={ALL} title={t('All')}/>
            </ul>
        </div>
    );
};

export default LiveTrackingTopbar;