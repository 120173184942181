/**
 Common colors
*/

const COLORS = {
    GREEN: '#669900',
    RED: '#993300',
    GRAY: '#555555',
    ORANGE: '#FFA500'
};

export default COLORS;