/**
 * User preferences & language translation
 */
import React, {useEffect} from 'react';
import {useApolloClient, useQuery, useReactiveVar} from "@apollo/client";
import USER_PREFERENCES_GQL from './user-preferences.graphql';
import LANGUAGE_TRANSLATION_GQL from '../../../language-setup/translation.graphql';
import Error from "../../ui/error";
import {userPreferencesVar} from "./cache";
import {accountVar} from "../../auth/cache";
import Shimmer from "../../ui/shimmer";
import {languageTranslationVar} from "../../translation/cache";
import {t} from "../../translation";

const UserPreferencesAndTranslation = ({children}) => {
    const apolloClient = useApolloClient();
    const accountVarState = useReactiveVar(accountVar);
    const userPreferencesState = useReactiveVar(userPreferencesVar);
    const languageTranslationState = useReactiveVar(languageTranslationVar);
    const {data: prefData, error: prefError} = useQuery(USER_PREFERENCES_GQL, {client: apolloClient, skip: !accountVarState?.isUser});
    const {data: langData, error: langError} = useQuery(LANGUAGE_TRANSLATION_GQL, {client: apolloClient, variables: {languageCode: prefData?.result?.languageCode}, skip: prefData?.result?.languageCode === undefined || prefData?.result?.languageCode === 'en'});

    /**
     * Set preferences
     */
    useEffect(() => {
        if(prefData?.result)
        {
            const localState = Object.keys(userPreferencesState).sort().map(key => `${key}:${userPreferencesState[key]}`);
            const remoteState = Object.keys(prefData.result).sort().map(key => `${key}:${prefData.result[key]}`);
            if(localState.join(',') !== remoteState.join(','))
            {
                userPreferencesVar(prefData.result);
            }
        }
    }, [prefData, userPreferencesState]);

    /**
     * Set translation
     */
    useEffect(() => {
        if(langData?.result)
        {
            //normalize translations to a dictionary
            const normalized = {};
            for(const translation of langData.result)
            {
                normalized[translation.code.toLocaleLowerCase()] = translation.label;
            }
            languageTranslationVar(normalized);
        }
    }, [langData]);

    if(prefError || langError)
    {
        return <Error>{t('Something went wrong!')}</Error>
    }
    if(accountVarState !== null && (!accountVarState.isUser || (Object.keys(userPreferencesState).length > 0 && (prefData?.result?.languageCode === 'en' || languageTranslationState !== null))))
    {
        return children;
    }
    return <div style={{paddingTop: '15%'}}><Shimmer/></div>
};

export default UserPreferencesAndTranslation;