/**
 Device-state selector
 */

import React from 'react';
import '../../../ui/semantic-ui/style.scss';
import SemanticDropdown from "../../../ui/widgets/semantic-dropdown";
import {t} from "../../../translation";

const options = [
    {value: 'm', label: 'MOVING'},
    {value: 'i', label: 'IDLING'},
    {value: 's', label: 'STOPPED'},
    {value: 'd', label: 'DISCONNECTED'},
];

const DeviceStateSelector = ({targetStates=null, ...props}) => {
    const placeholder = t(`Select ${props.multiple === false? 'state': 'states'}`);
    const customOptions = options
        .filter(entry => targetStates === null || targetStates.includes(entry.value))
        .map((entry) => ({key: entry.value, value: entry.value, text: t(entry.label)}));

    return <SemanticDropdown {...props} customOptions={customOptions} placeholder={placeholder}/>
};

export default DeviceStateSelector;