/**
 Geofence selector
*/

import React from 'react';
import {useQuery} from '@apollo/client';
import Loading from "../../loading";
import Error from '../../error';
import GEOFENCES_GQL from './geofences.graphql';
import '../../semantic-ui/style.scss';
import SemanticDropdown from "../semantic-dropdown";
import {t} from "../../../translation";

const GeofenceSelector = (props) => {
    const {data, error, loading} = useQuery(GEOFENCES_GQL, {client: props.client, variables: {filter: {}, pagination: {page: 0, offset: 0, applyPagination: false}}});
    if(error) return <Error>{t(error.message)}</Error>;
    if(loading) return <Loading/>;
    const placeholder = t(`Select ${props.multiple? 'geofences': 'geofence'}`);
    const customOptions = data.result.map((entry) => ({key: entry.id, value: entry.id, text: entry.name}));
    return <SemanticDropdown {...props} customOptions={customOptions} placeholder={placeholder}/>
};

export default React.memo(GeofenceSelector);