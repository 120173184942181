/**
 * High-priority user notifications
 */

import {useEffect} from 'react';
import {useApolloClient, useQuery} from "@apollo/client";
import HIGH_PRIORITY_GQL from "./notifications.graphql";
import {NO_CACHE} from "../../common/http/options";
import {isRtl} from "../../common/translation";

const notificationOptions = {
    pauseDelayOnHover: true,
    size: 'mini',
    rounded: true,
    sound: false,
    delayIndicator: false,
    icon: 'bx bx-error',
    continueDelayOnInactiveTab: false,
    position: 'bottom left',
    showAfterPrevious: true
};

const HighPriorityNotifications = () => {
    const apolloClient = useApolloClient();
    const {data} = useQuery(HIGH_PRIORITY_GQL, NO_CACHE(apolloClient, {}, false, {pollInterval: 60000}));

    useEffect(() => {
        if(data?.result?.length && window.Lobibox !== undefined)
        {
            for(const message of data.result)
            {
                window.Lobibox.notify('warning', {...notificationOptions, msg: `<div style="direction: ${isRtl()? 'rtl': 'ltr'};">${message}</div>`});
            }
        }
    }, [data]);

    return null;
};

export default HighPriorityNotifications;