/**
 * User settings
 */

import React, {useState} from 'react';
import {setDefaultValues, validityObjContainsFalse} from "../../utils/state";
import {getNextRenderer, renderNestedQueries} from "../../utils/renderer";
import 'react-datepicker/dist/react-datepicker.min.css';
import '../../ui/datepicker/style.scss';
import CountrySelector from "./country-selector";
import NumericSpinner from "../../ui/widgets/spinner";
import LanguageSelector from "./language-selector";
import FileUploader from "../../ui/widgets/uploader";
import {fields as FIELDS} from './fields';
import {SliderPicker} from "react-color";
import {useQuery} from "@apollo/client";
import USER_PREFERENCES_GQL from "./user-preferences.graphql";
import SET_PREFERENCES_GQL from './set-preference.graphql';
import Error from "../../ui/error";
import Shimmer from "../../ui/shimmer";
import {isEmptyStr, isValidFloat, isValidInt, isValidLatOrLng, isValidPhone} from "../../utils/validation";
import {submitMutation} from '../../http/mutate';
import Loading from "../../ui/loading";
import {isRtl, langDirStyle, t, textAlignStyle} from "../../translation";


const UserSettingsModal = ({apolloClient, RAlert}) => {
    const {data: prefData, loading: prefLoading, error: prefError} = useQuery(USER_PREFERENCES_GQL, {client: apolloClient});
    const [formValues, setFormValues] = useState(null);
    const [formValidity, setFormValidity] = useState(setDefaultValues(FIELDS, {}, {}, true));
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    //main logo
    const [isUploadingMainLogo, setIsUploadingMainLogo] = useState(false);
    const [mainLogoUploadError, setMainLogoUploadError] = useState(null);
    const [mainLogoUploadResult, setMainLogoUploadResult] = useState(null);
    //reports logo
    const [isUploadingReportsLogo, setIsUploadingReportsLogo] = useState(false);
    const [reportsLogoUploadError, setReportsLogoUploadError] = useState(null);
    const [reportsLogoUploadResult, setReportsLogoUploadResult] = useState(null);
    if(!prefLoading && !prefError && formValues === null)
    {
        const overrides = {
            ...(prefData?.result ?? {}),
            utcOffsetHours: parseFloat(((prefData?.result?.utcOffsetSecs ?? 0) / 3600).toFixed(2)),
            initialCoordinates: prefData?.result?.initialCoordinates?.lat === undefined? '': `${prefData.result.initialCoordinates.lat},${prefData.result.initialCoordinates.lng}`
        };
        setFormValues(setDefaultValues(FIELDS, {}, overrides));
        setMainLogoUploadResult(prefData?.result?.logo === null? null: {name: t('Main logo'), url: prefData.result.logo});
        setReportsLogoUploadResult(prefData?.result?.customReportsLogo === null? null: {name: t('Reports\' logo'), url: prefData.result.customReportsLogo});
    }
    /**
     * On form change
     */
    const onFormChange = ({target}) => {
        setFormValues({...formValues, [target.id]: target.value});
    };
    /**
     * On validation
     */
    const validateFields = () => {
        const formState = {...formValidity};
        formState.country = !isEmptyStr(formValues.country);
        formState.utcOffsetHours = isValidFloat(formValues.utcOffsetHours);
        formState.languageCode = !isEmptyStr(formValues.languageCode);
        formState.initialCoordinates = !isEmptyStr(formValues.initialCoordinates);
        if(formState.initialCoordinates)
        {
            const coords = formValues.initialCoordinates.split(',');
            formState.initialCoordinates = coords.length === 2 && isValidLatOrLng(coords[0].trim()) && isValidLatOrLng(coords[1].trim());
        }
        formState.initialZoomLevel = isValidInt(formValues.initialZoomLevel) && parseInt(formValues.initialZoomLevel) >= 0;
        formState.defaultMapDisplay = !isEmptyStr(formValues.defaultMapDisplay);
        formState.showLiveTraffic = !isEmptyStr(formValues.showLiveTraffic);
        formState.showMapPointsOfInterest = !isEmptyStr(formValues.showMapPointsOfInterest);
        formState.followTracker = !isEmptyStr(formValues.followTracker);
        formState.displayLandmarksInLiveView = !isEmptyStr(formValues.displayLandmarksInLiveView);
        formState.displayGeofencesInLiveView = !isEmptyStr(formValues.displayGeofencesInLiveView);
        formState.trackerLiveTripStrokeColor = !isEmptyStr(formValues.trackerLiveTripStrokeColor);
        formState.contactName = !isEmptyStr(formValues.contactName);
        formState.contactMobile = isEmptyStr(formValues.contactMobile) || isValidPhone(formValues.contactMobile);
        formState.password = isEmptyStr(formValues.password) || formValues.password.trim().length >= 6;
        formState.confirmPassword = formState.password && formValues.password.trim() === formValues.confirmPassword.trim();
        setFormValidity(formState);
        return !validityObjContainsFalse(formState);
    };
    /**
     * On apply click
     */
    const onApplyClick = async () => {
        if(!validateFields())
        {
            return;
        }
        const coords = formValues.initialCoordinates.split(',');
        const payload = {
            country: formValues.country,
            languageCode: formValues.languageCode,
            utcOffsetSecs: Math.round(parseFloat(formValues.utcOffsetHours) * 3600),
            initialCoordinates: {lat: parseFloat(coords[0].trim()), lng: parseFloat(coords[1].trim())},
            initialZoomLevel: parseInt(formValues.initialZoomLevel),
            defaultMapDisplay: formValues.defaultMapDisplay,
            showLiveTraffic: [true, 'true'].includes(formValues.showLiveTraffic),
            showMapPointsOfInterest: [true, 'true'].includes(formValues.showMapPointsOfInterest),
            followTracker: [true, 'true'].includes(formValues.followTracker),
            displayLandmarksInLiveView: [true, 'true'].includes(formValues.displayLandmarksInLiveView),
            displayGeofencesInLiveView: [true, 'true'].includes(formValues.displayGeofencesInLiveView),
            trackerLiveTripStrokeColor: formValues.trackerLiveTripStrokeColor,
            contactName: formValues.contactName.trim(),
            contactMobile: isEmptyStr(formValues.contactMobile)? null: formValues.contactMobile.trim(),
            password: isEmptyStr(formValues.password)? null: formValues.password.trim(),
            logo: mainLogoUploadResult === null? null: mainLogoUploadResult.url,
            customReportsLogo: reportsLogoUploadResult === null? null: reportsLogoUploadResult.url
        };
        const params = [
            apolloClient,
            SET_PREFERENCES_GQL,
            {payload},
            setErrorMessage,
            setIsLoading
        ];
        const {error} = await submitMutation(...params);
        if(!error)
        {
            RAlert.close();
            window.location.reload();
        }
    };
    /**
     * Renders card
     */
    const renderCard = (args, prevData, ...next) => {
        return (
            <div className="card borderless shadow-none" style={{height: '100%', ...langDirStyle()}}>
                <div className="card-header">
                    <div className="text-center p-2" style={{fontWeight: 'bolder'}}>
                        {t('Settings')}
                    </div>
                </div>
                <div className="card-body" style={{overflowY: 'auto', overflowX: 'hidden'}}>
                    {prefLoading && <Shimmer/>}
                    {prefError && <Error>{t('Something went wrong!')}</Error>}
                    {!prefLoading && !prefError && formValues !== null && getNextRenderer(args, prevData, ...next)}
                </div>
                <div className="card-footer p-3">
                    {/** footer */}
                    <div className="row">
                        <div className="input-group justify-content-center">
                            <button type="button" className={`btn btn-outline-secondary ${isRtl()? 'ms-3': ''}`} onClick={() => RAlert.close()}>
                                <i className="bx bx-window-close"/>{t('Close')}
                            </button>
                            <button className={`btn btn-success ${!isRtl()? 'ms-3': ''}`} onClick={onApplyClick} disabled={isLoading}>
                                {
                                    !isLoading &&
                                    <>
                                        <i className="bx bx-check-circle"/>
                                        {t('Apply')}
                                    </>
                                }
                                {
                                    isLoading &&
                                    <Loading/>
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    /**
     * Regional settings
     */
    const renderRegionalSettings = (args, prevData, ...next) => {
        return (
            <div className="animated fadeIn">
                {
                    errorMessage && <>
                        <Error>{t(errorMessage)}</Error>
                        <hr/>
                    </>
                }
                <div className="form-group p-2">
                    <h6 className="mb-0 text-uppercase" style={{...textAlignStyle()}}>
                        <i className="bx bx-globe me-1"/>
                        {t('Regional settings')}
                    </h6>
                    <hr/>
                    <div className="row">
                        {/* country */}
                        <div className="col-md-4" style={{...textAlignStyle()}}>
                            <label htmlFor="country" className="form-label required">{t('Country')}</label>
                            <CountrySelector domID={'country'} selectedValues={formValues.country} onValueChange={onFormChange}
                                             client={apolloClient} isInvalid={!formValidity.country}/>
                        </div>
                        {/* utcOffsetHours */}
                        <div className="col-md-4" style={{...textAlignStyle()}}>
                            <label htmlFor="utcOffsetHours" className="form-label required">{t('UTC offset, in hours')}</label>
                            <NumericSpinner domID={'utcOffsetHours'} spinnerValue={formValues.utcOffsetHours} isInvalid={!formValidity.utcOffsetHours}
                                            min={-23} max={23} step={0.1} onValueChange={onFormChange}/>
                        </div>
                        {/* languageCode */}
                        <div className="col-md-4" style={{...textAlignStyle()}}>
                            <label htmlFor="languageCode" className="form-label required">{t('Language')}</label>
                            <LanguageSelector domID={'languageCode'} selectedValues={formValues.languageCode} onValueChange={onFormChange}
                                             client={apolloClient} isInvalid={!formValidity.languageCode}/>
                        </div>
                    </div>
                </div>
                {getNextRenderer(args, prevData, ...next)}
            </div>
        );
    };
    /**
     * Map
     */
    const renderMap = (args, prevData, ...next) => {
        return (
            <div className="animated fadeIn">
                <div className="form-group p-2 mt-3">
                    <h6 className="mb-0 text-uppercase" style={{...textAlignStyle()}}>
                        <i className="bx bx-map me-1"/>
                        {t('Map')}
                    </h6>
                    <hr/>
                    <div className="row">
                        {/* initialCoordinates */}
                        <div className="col-md-4" style={{...textAlignStyle()}}>
                            <label htmlFor="initialCoordinates" className="form-label required">{t('Initial coordinates')}</label>
                            <input type="text" id={'initialCoordinates'} className={'form-control' + (!formValidity.initialCoordinates? ' is-invalid': '')}
                                   value={formValues.initialCoordinates} onChange={onFormChange} placeholder={'latitude,longitude'}/>
                        </div>
                        {/* initialZoomLevel */}
                        <div className="col-md-4" style={{...textAlignStyle()}}>
                            <label htmlFor="initialZoomLevel" className="form-label required">{t('Initial zoom level')}</label>
                            <NumericSpinner domID={'initialZoomLevel'} spinnerValue={formValues.initialZoomLevel} isInvalid={!formValidity.initialZoomLevel}
                                            min={0} max={19} onValueChange={onFormChange}/>
                        </div>
                        {/* defaultMapDisplay */}
                        <div className="col-md-4" style={{...textAlignStyle()}}>
                            <label htmlFor="defaultMapDisplay" className="form-label">{t('Default map type')}</label>
                            <select id={'defaultMapDisplay'} value={formValues.defaultMapDisplay} onChange={onFormChange} className={'form-control' + (!formValidity.defaultMapDisplay? ' is-invalid': '')}>
                                <option value={''}>{t('Select')}</option>
                                <option value={'map'}>{t('Map')}</option>
                                <option value={'earth'}>{t('Satellite')}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group p-2">
                    <div className="row">
                        {/* showLiveTraffic */}
                        <div className="col-md-4" style={{...textAlignStyle()}}>
                            <label htmlFor="showLiveTraffic" className="form-label">{t('Display live traffic feed')}</label>
                            <select id={'showLiveTraffic'} value={formValues.showLiveTraffic} onChange={onFormChange} className={'form-control' + (!formValidity.showLiveTraffic? ' is-invalid': '')}>
                                <option value={true}>{t('Yes')}</option>
                                <option value={false}>{t('No')}</option>
                            </select>
                        </div>
                        {/* showMapPointsOfInterest */}
                        <div className="col-md-4" style={{...textAlignStyle()}}>
                            <label htmlFor="showMapPointsOfInterest" className="form-label">{t('Display map\'s default POI')}</label>
                            <select id={'showMapPointsOfInterest'} value={formValues.showMapPointsOfInterest} onChange={onFormChange} className={'form-control' + (!formValidity.showMapPointsOfInterest? ' is-invalid': '')}>
                                <option value={true}>{t('Yes')}</option>
                                <option value={false}>{t('No')}</option>
                            </select>
                        </div>
                        {/* followTracker */}
                        <div className="col-md-4" style={{...textAlignStyle()}}>
                            <label htmlFor="followTracker" className="form-label">{t('Follow tracker\'s updates in live view')}</label>
                            <select id={'followTracker'} value={formValues.followTracker} onChange={onFormChange} className={'form-control' + (!formValidity.followTracker? ' is-invalid': '')}>
                                <option value={true}>{t('Yes')}</option>
                                <option value={false}>{t('No')}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group p-2">
                    <div className="row">
                        {/* displayLandmarksInLiveView */}
                        <div className="col-md-4" style={{...textAlignStyle()}}>
                            <label htmlFor="displayLandmarksInLiveView" className="form-label">{t('Display landmarks in live view')}</label>
                            <select id={'displayLandmarksInLiveView'} onChange={onFormChange} value={formValues.displayLandmarksInLiveView} className={'form-control' + (!formValidity.displayLandmarksInLiveView? ' is-invalid': '')}>
                                <option value={true}>{t('Yes')}</option>
                                <option value={false}>{t('No')}</option>
                            </select>
                        </div>
                        {/* displayGeofencesInLiveView */}
                        <div className="col-md-4" style={{...textAlignStyle()}}>
                            <label htmlFor="displayGeofencesInLiveView" className="form-label">{t('Display geofences in live view')}</label>
                            <select id={'displayGeofencesInLiveView'} onChange={onFormChange} value={formValues.displayGeofencesInLiveView} className={'form-control' + (!formValidity.displayGeofencesInLiveView? ' is-invalid': '')}>
                                <option value={true}>{t('Yes')}</option>
                                <option value={false}>{t('No')}</option>
                            </select>
                        </div>
                        {/* trackerLiveTripStrokeColor */}
                        <div className="col-md-4" style={{...textAlignStyle()}}>
                            <label className="form-label">{t('Tracker\'s live trip route color')}</label>
                            <SliderPicker color={formValues.trackerLiveTripStrokeColor ?? '#000000'} onChangeComplete={(color) => {setFormValues({...formValues, trackerLiveTripStrokeColor: color.hex});}}/>
                        </div>
                    </div>
                </div>
                {getNextRenderer(args, prevData, ...next)}
            </div>
        );
    };
    /**
     * Contact details
     */
    const renderContactDetails = (args, prevData, ...next) => {
        return (
            <div className="animated fadeIn">
                <div className="form-group p-2 mt-3">
                    <h6 className="mb-0 text-uppercase" style={{...textAlignStyle()}}>
                        <i className="bx bx-user me-1"/>
                        {t('Contact details')}
                    </h6>
                    <hr/>
                    <div className="row">
                        {/* contactName */}
                        <div className="col-md-4" style={{...textAlignStyle()}}>
                            <label htmlFor="contactName" className="form-label required">{t('Name')}</label>
                            <input type="text" id={'contactName'} className={'form-control' + (!formValidity.contactName? ' is-invalid': '')}
                                   value={formValues.contactName} onChange={onFormChange}/>
                        </div>
                        {/* contactMobile */}
                        <div className="col-md-4" style={{...textAlignStyle()}}>
                            <label htmlFor="contactMobile" className="form-label">{t('Mobile')}</label>
                            <input type="text" id={'contactMobile'} className={'form-control' + (!formValidity.contactMobile? ' is-invalid': '')}
                                   value={formValues.contactMobile} onChange={onFormChange} placeholder={'+966501234567'} autoComplete="off" style={{direction: 'ltr', ...textAlignStyle()}}/>
                        </div>
                    </div>
                </div>
                {getNextRenderer(args, prevData, ...next)}
            </div>
        );
    };
    /**
     * Password
     */
    const renderPassword = (args, prevData, ...next) => {
        return (
            <div className="animated fadeIn">
                <div className="form-group p-2 mt-3">
                    <h6 className="mb-0 text-uppercase" style={{...textAlignStyle()}}>
                        <i className="bx bx-key me-1"/>
                        {t('Password')}
                    </h6>
                    <hr/>
                    <div className="row">
                        {/* password */}
                        <div className="col-md-4" style={{...textAlignStyle()}}>
                            <label htmlFor="password" className="form-label">{t('Password')}</label>
                            <input type="password" id={'password'} className={'form-control' + (!formValidity.password? ' is-invalid': '')}
                                   value={formValues.password} onChange={onFormChange} placeholder={t('6 or more chars')} autoComplete="new-password"/>
                            {
                                isEmptyStr(formValues.password) &&
                                <small className="text-muted">{t('Leave it blank to skip changing your password')}</small>
                            }
                            {
                                !isEmptyStr(formValues.password) &&
                                <small className="text-muted"><i className="bx bx-info-circle"/> {t('You\'ll be logged out after submission, sign in again with the new password')}</small>
                            }
                        </div>
                        {/* confirmPassword */}
                        <div className="col-md-4" style={{...textAlignStyle()}}>
                            <label htmlFor="confirmPassword" className="form-label">{t('Confirm password')}</label>
                            <input type="password" id={'confirmPassword'} className={'form-control' + (!formValidity.confirmPassword? ' is-invalid': '')}
                                   value={formValues.confirmPassword} onChange={onFormChange} autoComplete="off"/>
                        </div>
                    </div>
                </div>
                {getNextRenderer(args, prevData, ...next)}
            </div>
        );
    };
    /**
     * Logos
     */
    const renderLogos = (args, prevData, ...next) => {
        return (
            <div className="animated fadeIn">
                <div className="form-group p-2 mt-3">
                    <h6 className="mb-0 text-uppercase" style={{...textAlignStyle()}}>
                        <i className="bx bx-photo-album me-1"/>
                        {t('Logo')}
                    </h6>
                    <hr/>
                    <div className="row">
                        {/* mainLogo */}
                        <div className="col-md-4" style={{...textAlignStyle()}}>
                            <label className="form-label">{t('Main logo')}</label>
                            <FileUploader apolloClient={apolloClient} setUploadFileResult={setMainLogoUploadResult} setUploadFileError={setMainLogoUploadError}
                                          setIsUploadingFile={setIsUploadingMainLogo} isUploadingFile={isUploadingMainLogo} uploadFileError={mainLogoUploadError}
                                          uploadFileResult={mainLogoUploadResult}/>
                        </div>
                        {/* reportsLogo */}
                        <div className="col-md-4" style={{...textAlignStyle()}}>
                            <label className="form-label">{t('Custom logo to use in reports')}</label>
                            <FileUploader apolloClient={apolloClient} setUploadFileResult={setReportsLogoUploadResult} setUploadFileError={setReportsLogoUploadError}
                                          setIsUploadingFile={setIsUploadingReportsLogo} isUploadingFile={isUploadingReportsLogo} uploadFileError={reportsLogoUploadError}
                                          uploadFileResult={reportsLogoUploadResult}/>
                        </div>
                    </div>
                </div>
                {
                    errorMessage && <>
                        <Error>{t(errorMessage)}</Error>
                        <hr/>
                    </>
                }
                {getNextRenderer(args, prevData, ...next)}
            </div>
        );
    };
    return (
        renderNestedQueries(
            {},
            {},
            renderCard,
            renderRegionalSettings,
            renderMap,
            renderContactDetails,
            renderPassword,
            renderLogos
        )
    );
};

export default UserSettingsModal;