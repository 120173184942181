/**
 The top header
*/

import React from 'react';
import firebase from 'firebase/app';
import {accountVar} from "../../auth/cache";
import {useLocation} from 'react-router-dom';
import Avatar from './avatar.png';
import LiveTrackingTopbar from "../../../live-tracking/topbar";
import LiveTrackingSearch from "../../../live-tracking/topbar/search";
import {isDistributor, isMaster, isUser} from "../../auth/guard/check";
import Geocode from "../../../live-tracking/geocode";
import GeofenceGeometryTopbar from "../../../geofences/geometry-bar";
import LandmarkTopbar from "../../../landmarks/topbar";
import PlaybackTopbar from "../../../playback/topbar";
import NotificationsViewer from "../../../notifications/viewer";
import launchModal from '../../ui/sweet-alert';
import {FULLSCREEN_MODAL_OPTIONS} from "../sweet-alert/fullscreen";
import UserSettingsModal from "../../user/settings";
import {useApolloClient} from "@apollo/client";
import {isRtl, t} from "../../translation";

/**
 * signOut
 */
const signOut = async (e) => {
    e.preventDefault();
    await firebase.auth().signOut();
    localStorage.clear();
    window.location.reload();
};

const PATHS_WITH_TOPBAR = [
    '/live',
    '/live/track',
    '/geofences/geometry',
    '/landmarks',
    '/playback'
];

const Header = () => {
    const apolloClient = useApolloClient();
    const location = useLocation();
    const myAccount = accountVar();
    return (
        <header>
            <div className={`topbar d-flex align-items-center app-direction-${isRtl()? 'rtl': 'ltr'}`}>
                <nav className="navbar navbar-expand">
                    <div className="mobile-toggle-menu">
                        <i className='bx bx-menu'/>
                    </div>
                    {/* to maintain the layout */}
                    {
                        (isMaster() || isDistributor() || ((isUser() && !PATHS_WITH_TOPBAR.includes(location.pathname))))&&
                        <div className="search-bar flex-grow-1"/>
                    }
                    {
                        (isUser() && /^\/live/.test(location.pathname)) &&
                        <div className="search-bar flex-grow-1" style={{fontSize: '1.2em'}}>
                            <div className="position-relative search-bar-box" style={{width: '60%'}}>
                                <LiveTrackingSearch/>
                            </div>
                        </div>
                    }
                    {
                        (isUser() && /^\/live/.test(location.pathname)) &&
                        <LiveTrackingTopbar/>
                    }
                    {
                        (isUser() && (location.pathname === '/geofences/geometry' || location.pathname === '/landmarks')) &&
                        <div className="search-bar flex-grow-1" style={{fontSize: '1.5em'}}>
                            <Geocode/>
                        </div>
                    }
                    {
                        (isUser() && location.pathname === '/geofences/geometry') &&
                        <GeofenceGeometryTopbar/>
                    }
                    {
                        (isUser() && location.pathname === '/landmarks') &&
                        <LandmarkTopbar/>
                    }
                    {
                        (isUser() && location.pathname === '/playback') &&
                        <PlaybackTopbar/>
                    }
                    {
                        isUser() &&
                        <NotificationsViewer/>
                    }
                    <div className="user-box dropdown">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src={myAccount.logo ?? Avatar} className="user-img" alt="user avatar"/>
                            <div className="user-info ps-3">
                                <p className="user-name mb-0">{myAccount.name}</p>
                                <p className="designattion mb-0">{myAccount.companyName ?? ''}</p>
                            </div>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end">
                            {
                                isUser() &&
                                <li>
                                    <a className="dropdown-item" href="/" onClick={(e) => {
                                        e.preventDefault();
                                        launchModal(UserSettingsModal, {apolloClient}, FULLSCREEN_MODAL_OPTIONS);
                                    }}>
                                        <i className="bx bx-cog"/>
                                        <span>{t('Settings')}</span>
                                    </a>
                                </li>
                            }
                            <li>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a className="dropdown-item" href="#" onClick={signOut}>
                                    <i className='bx bx-log-out-circle'/>
                                    <span>{t('Logout')}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default Header;