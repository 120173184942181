/**
 Generic utility methods when working with cache, state or forms
*/
import omitDeep from './omit-deep';

/**
 * Populates initial values for form state
 * @param {Array} fields list of fields names
 * @param {Object} state field-name to initial value, can be empty
 * @param {Object} override overrides `state` for present fields
 * @param defaultValue global default value if none is found in `state` or `override`
 * @param skipFields a list of fields names to skip if found
 */
export const setDefaultValues = (fields, state, override, defaultValue = '', skipFields = []) => {
    const allFields = {};
    for(let field of fields)
    {
        if(skipFields.includes(field))
        {
            continue;
        }
        let value = state[field];
        if(override[field] !== undefined)
        {
            value = override[field];
        }
        if(value === undefined || value === null)
        {
            value = defaultValue;
        }
        allFields[field] = value;
    }
    return allFields;
};
/**
 * Takes a validity object where each key resembles a field ID with value
 * indicating if field is valid or not
 */
export const validityObjContainsFalse = (validity) => {
    return Object.values(validity).includes(false);
};

/**
 * Removes __typename from object
 */
export const stripTypeName = (value) => {
    return omitDeep({...value}, '__typename');
};